import { useEffect, useRef, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { getHeadersForRequest, numberWithCommas } from "../utils/functions";
import axios from "axios";
import {
  Button,
  Center,
  HStack,
  Img,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Text,
  useColorMode,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Loading } from "../components/Loading";
import moment from "moment";
import Nav from "../components/navbar";
import Cookies from "js-cookie";
import jsPDF from "jspdf";
import "jspdf-autotable";
import SignatureCanvas from "react-signature-canvas";
import ReactCrop, { Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css"; // Import CSS for react-image-crop
import { DeleteIcon } from "@chakra-ui/icons";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import { SupplierList } from "../utils/types";
import { getSupplierList } from "../utils/API_calls";

const logo = require("../resources/images/logo.jpg");
const logo2 = require("../resources/images/koas-logo.png");

type OrderList = {
  id: number;
  description: string;
  qty: string;
  price: string;
  transactionNumber: number;
  userId: string;
  transactionDate: Date;
  totalAmount: number;
  customerId: number;
  paymentStatus: number;
  paymentDate: Date;
  deliveryStatus: boolean;
  deliveryDate: Date;
  physicalDeliveryNote: boolean;
  deliveryNoteNumber: string;
  transactionType: number;
  supplierId: number;
  invoiceDate: Date;
  isVoid: boolean;
  name: string;
  tin: string;
  phoneNumber: string;
  location: string;
  traCode: string;
  traTime: string;
  QRLink: string;
  traReceiptNumber: string;
  refInvoice: string;
  signature: string;
};

interface ExpenseDetailsScreenProps {
  ID: string;
}

const ExpenseDetailsScreen: React.FC<ExpenseDetailsScreenProps> = ({ ID }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modalIsLoading, setModalIsLoading] = useState<boolean>(false);
  const [checkingForResources, setCheckingForResources] =
    useState<boolean>(false);
  const [orders, setOrders] = useState<OrderList[]>([]);
  const [resourceFound, setResourceFound] = useState<boolean>(false);
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const { colorMode, toggleColorMode } = useColorMode();
  const toast = useToast();
  const [err, setErr] = useState("");
  const { orderId } = useParams<{ orderId: string }>();

  const logo1 = require("../resources/images/logo1.png");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  const [supplierSignature, setSupplierSignature] = useState<string | null>(
    null
  );
  const [staffSignature, setStaffSignature] = useState<string | null>(null);
  const [isSignatureModalOpen, setIsSignatureModalOpen] = useState(false);
  const [isPhotoListModalOpen, setIsPhotoListModalOpen] =
    useState<boolean>(false);
  const [images, setImages] = useState<string[]>([]);
  const [pdfFile, setPdfFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const pdfInputRef = useRef<HTMLInputElement | null>(null);
  const sigCanvas = useRef<any>();

  const [croppedImageUrl, setCroppedImageUrl] = useState<string | null>(null);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [imageToView, setImageToView] = useState<string | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const [crop, setCrop] = useState<Crop>({
    unit: "%",
    width: 50,
    height: 50,
    x: 0,
    y: 0,
  });

  const [suppliersList, setSuppliersList] = useState<SupplierList[]>([]);
  const [selectedSupplier, setSelectedSupplier] = useState();
  const [showSupplierSelectionModal, setShowSupplierSelectionModal] =
    useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    getOrderDetails();
    getStaffSignature();
    // getSupplierSignature();
    console.log(orderId);
  }, []);

  useEffect(() => {
    checkFileExists();
  }, [orders]);

  // Function to handle image capture
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target) {
          setImageSrc(event.target.result as string); // Set the image source as base64
        }
      };
      reader.readAsDataURL(file); // Read image as base64
    }
  };

  // Function to crop the image with higher quality
  const getCroppedImg = useCallback(() => {
    if (!imageRef.current || !crop.width || !crop.height) {
      return;
    }

    const canvas = document.createElement("canvas");
    const scaleX = imageRef.current.naturalWidth / imageRef.current.width;
    const scaleY = imageRef.current.naturalHeight / imageRef.current.height;

    const pixelCropWidth = crop.width * scaleX;
    const pixelCropHeight = crop.height * scaleY;

    canvas.width = pixelCropWidth;
    canvas.height = pixelCropHeight;

    const ctx = canvas.getContext("2d");

    // Disable image smoothing to retain higher quality
    if (ctx) {
      ctx.imageSmoothingEnabled = false; // Disable smoothing
      ctx.imageSmoothingQuality = "high"; // Optional: Set image smoothing quality

      ctx.drawImage(
        imageRef.current,
        crop.x! * scaleX,
        crop.y! * scaleY,
        pixelCropWidth,
        pixelCropHeight,
        0,
        0,
        pixelCropWidth,
        pixelCropHeight
      );
    }

    const croppedImageUrl = canvas.toDataURL("image/jpeg", 1.0); // Use 1.0 for highest quality
    setCroppedImageUrl(croppedImageUrl); // Set the cropped image URL for preview
  }, [crop]);

  // Open the signature modal for customer or staff
  const openSignatureModal = () => {
    setIsSignatureModalOpen(true);
  };

  // Clear signature canvas
  const clearSignature = () => {
    sigCanvas.current.clear();
  };

  // Save the signature as base64 for the active signer
  const saveSignature = async () => {
    setModalIsLoading(true);
    const headers = await getHeadersForRequest();
    const signatureData = sigCanvas.current
      .getTrimmedCanvas()
      .toDataURL("image/png");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_TEST_API_URL}/transactions/signature`,
        { signature: signatureData, id: orders[0].id },
        { headers }
      );

      if (response.status === 200) {
        console.log("Signature saved successfully");
        setIsSignatureModalOpen(false);
        setModalIsLoading(false);
        getOrderDetails();
      } else {
        console.log("Error saving signature");

        setModalIsLoading(false);
      }
    } catch (error) {
      console.error(error);

      setModalIsLoading(false);
    }
  };

  // Check if addtional resources are available on google drive
  const checkFileExists = async () => {
    const headers = await getHeadersForRequest();
    setCheckingForResources(true);
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_TEST_API_URL
        }/google/check-file/${orders[0].transactionNumber.toString()}.pdf`,
        { headers }
      );
      setCheckingForResources(false);
      if (response.status === 200) {
        setResourceFound(true);
      } else {
        setResourceFound(false);
      }
    } catch (error) {
      console.error(error);
      setCheckingForResources(false);
    }
  };

  // Get Expense Details
  const getOrderDetails = async () => {
    setIsLoading(true);
    const oldHeaders = await getHeadersForRequest();
    const newHeaders = {
      orderid: orderId ? orderId : ID,
    };
    const headers = { ...oldHeaders, ...newHeaders };

    try {
      const response = await axios.get<OrderList[]>(
        `${process.env.REACT_APP_TEST_API_URL}/transactions/orderdetails/expenses`,
        { headers }
      );
      console.log(response.data);
      setOrders(response.data);
      setIsLoading(false);
      getSupplierSignature(response.data[0].id);
      console.log(response.data);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  // Download additional resources
  const downloadResources = async () => {
    const headers = await getHeadersForRequest();
    setIsLoading(true);
    console.log("Starting download...");

    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_TEST_API_URL
        }/google/download/${orders[0].transactionNumber.toString()}.pdf`,
        { headers, responseType: "arraybuffer" } // Ensure raw binary data
      );

      // Step 1: Create a Blob from the response data
      const file = new Blob([response.data], { type: "application/pdf" });

      // Step 2: Create a URL for the Blob
      const fileURL = URL.createObjectURL(file);

      // Step 3: Create a link element and trigger the download
      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute("download", `${orders[0].transactionNumber}.pdf`); // File name
      document.body.appendChild(link);
      link.click(); // Programmatically click the link to trigger the download
      document.body.removeChild(link); // Clean up link element

      // Clean up the URL after the download completes
      URL.revokeObjectURL(fileURL);

      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      if (error.response && error.response.status === 404) {
        console.error("File not found.");
        alert("File not found. Please try again later.");
      } else {
        console.error("Error downloading the PDF:", error);
        alert(
          "An error occurred while downloading the file. Please try again."
        );
      }
    }
  };

  // Open new window to see the resources
  const viewResource = async () => {
    const headers = await getHeadersForRequest();
    setIsLoading(true);
    console.log("Starting file view...");

    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_TEST_API_URL
        }/google/download/${orders[0].transactionNumber.toString()}.pdf`,
        { headers, responseType: "arraybuffer" } // Ensure raw binary data
      );

      // Step 1: Create a Blob from the response data
      const file = new Blob([response.data], { type: "application/pdf" });

      // Step 2: Create a URL for the Blob
      const fileURL = URL.createObjectURL(file);

      // Step 3: Open the PDF in a new tab
      window.open(fileURL);

      // Clean up the URL after the file is opened
      URL.revokeObjectURL(fileURL);

      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      if (error.response && error.response.status === 404) {
        console.error("File not found.");
        alert("File not found. Please try again later.");
      } else {
        console.error("Error viewing the PDF:", error);
        alert("An error occurred while viewing the file. Please try again.");
      }
    }
  };

  //Cretates voucher PDF //Deprecated because not allowing page custom dimensions
  const downloadVoucher = async (data: any) => {
    setIsLoading(true);

    // Define your custom width and height (e.g., 150mm x 100mm)
    const customWidth = 10; // in mm
    const customHeight = 148; // in mm

    const doc = new jsPDF({
      orientation: "portrait",
      unit: "mm", // unit: 'pt', 'mm', 'cm', 'in'
      format: "a4", //[210, 148], // custom size as [width, height]
      // format: [210, 148]
    });

    console.log("data: " + JSON.stringify(data[0]));

    // Load an image and add it to the PDF
    const img = new Image();
    img.src = logo1;
    img.onload = async () => {
      // Get the dimensions of the image and the PDF
      const imgWidth = 30; // Adjust as needed
      const imgHeight = 30; // Adjust as needed
      const pageWidth = doc.internal.pageSize.getWidth();

      // Position the image in the top right corner
      const x = pageWidth - imgWidth - 15; // 10 units from the right edge
      const y = 20; // 10 units from the top edge

      // doc.addImage(img, "JPEG", x, y, imgWidth, imgHeight);

      // Add dynamic content
      doc.setFontSize(20);
      doc.text(`Expense Voucher`, 15, 15);
      doc.text(data[0].transactionNumber.toString(), pageWidth - 33, 15);
      doc.setFontSize(10);

      doc.text(`To: ${data[0].name ? data[0].name : "Cash"}`, 15, 25);
      doc.text(
        `Reference Invoice: ${data[0].refInvoice ? data[0].refInvoice : ""}`,
        15,
        30
      );
      doc.text(
        `Delivery Note: ${
          data[0].deliveryNoteNumber ? data[0].deliveryNoteNumber : ""
        }`,
        15,
        35
      );
      // data[0].tin && doc.text(`${data[0].tin}`, 15, 67);
      // Cookies.get("businessName") === "Keinth's Food" &&
      //   doc.text("105213395", pageWidth - 43, 58);
      doc.text(
        `${moment(data[0].transactionDate).format("DD MMM YYYY")}`,
        pageWidth - 45,
        35
      );

      // Table data
      const tableColumn = ["Description", "Amount"];
      const tableRows: any[] = [];

      const maxRows = 10;

      // Map your data and fill tableRows
      data.forEach(
        (item: { qty: number; description: string; price: number }) => {
          const tableRow = [
            item.description + "  (" + item.qty + ")",
            numberWithCommas(item.qty * item.price),
          ];
          tableRows.push(tableRow);
        }
      );

      // If the data has fewer than 10 rows, add empty rows
      while (tableRows.length < maxRows) {
        tableRows.push(["", ""]); // Push empty rows until we reach 10
      }

      let finalY = imgHeight + 30;

      console.log("doc " + JSON.stringify(tableRows));

      (doc as any).autoTable({
        head: [tableColumn],
        body: tableRows,
        startY: 40, // Start table below the image
        didDrawPage: (dataArg: any) => {
          finalY = dataArg.cursor.y; // Get Y position of the end of the table
        },
        headStyles: {
          fontSize: 10,
          fillColor: "#000",
        },
        bodyStyles: {
          fontSize: 10,
          font: "helvetica",
        },
        columnStyles: {
          0: { cellWidth: "auto" }, // First column (auto width)
          1: { halign: "right", cellWidth: 20 }, // Last column (right-align and auto width)
        },
        tableWidth: "auto", // Adjust table width automatically
      });

      // Add total
      const total = `Total: ${data[0].totalAmount}`;
      const totalX = pageWidth - doc.getTextWidth(total) - 30; // Right-align the total

      doc.setFontSize(15);
      doc.text(
        `TOTAL: ${numberWithCommas(data[0].totalAmount)}`,
        pageWidth - 45,
        130
      );

      if (supplierSignature) {
        // Add the customer signature image to the PDF
        doc.addImage(supplierSignature, "PNG", 50, 138, 10, 5); // Customer signature at bottom left
        // doc.text("Customer Signature", 20, 140); // Label below signature
      }

      if (staffSignature) {
        // Add the staff signature image to the PDF
        doc.addImage(staffSignature, "PNG", pageWidth - 40, 138, 10, 5); // Staff signature at bottom right
        // doc.text("Authorized Staff Signature", pageWidth - 50, 140); // Label below signature
      }

      doc.text(`Signature: `, 15, 143);

      doc.text(`Authorized: `, pageWidth - 70, 143);

      // Generate QR code and add to PDF
      const qrCodeValue = "this is a dummy QR for testing";

      try {
        // const qrCodeDataUrl = await QRCode.toDataURL(qrCodeValue);
        // doc.addImage(qrCodeDataUrl, "PNG", 10, finalY + 20, 50, 50); // Adjust position and size as needed
        // doc.text(
        //   `${data[0].traCode}${data[0].traReceiptNumber}`,
        //   70,
        //   finalY + 30
        // );
        // doc.text(
        //   `${data[0].traTime.slice(0, 2)}:${data[0].traTime.slice(
        //     2,
        //     4
        //   )}:${data[0].traTime.slice(4)}`,
        //   70,
        //   finalY + 40
        // );
      } catch (error) {
        console.error("QR Code generation error:", error);
      }

      // Generate PDF and get URL
      const pdfBlob = doc.output("blob");
      const pdfUrl = URL.createObjectURL(pdfBlob);

      setPdfUrl(pdfUrl);
      onOpen();
      setIsLoading(false);
      console.log(
        `PDF Dimensions: Width = ${doc.internal.pageSize.getWidth()} mm, Height = ${doc.internal.pageSize.getHeight()} mm`
      );
    };
  };

  //Cretates voucher PDF using PDF Lib
  const downloadVoucherPDFLIB = async (data: any) => {
    setIsLoading(true);

    // Create a new PDF document
    const pdfDoc = await PDFDocument.create();

    // Define your custom width and height in pt
    const customWidth = 595.28;
    const customHeight = 420.94;

    // Add a page with custom dimensions
    const page = pdfDoc.addPage([customWidth, customHeight]);

    const { width, height } = page.getSize();

    // Load an image (logo)
    const imgUrl = logo1; // replace with your image URL or data
    const imgBytes = await fetch(imgUrl).then((res) => res.arrayBuffer());
    const img = await pdfDoc.embedPng(imgBytes); // or embedJpg(imgBytes) for JPG format

    // Set positions and dimensions for image and text
    const imgWidth = 80;
    const imgHeight = 50;
    const x = page.getWidth() - imgWidth - 10;
    const y = page.getHeight() - imgHeight - 20;

    // Draw the image
    // page.drawImage(img, {
    //   x,
    //   y,
    //   width: imgWidth,
    //   height: imgHeight,
    // });

    // Load a font
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const titleFontSize = 20;
    const subtitleFontSize = 15;
    const textFontSize = 10;

    // Draw text
    page.drawText("Expense Voucher", {
      x: 15,
      y: page.getHeight() - 30,
      size: titleFontSize,
      font,
      color: rgb(0, 0, 0),
    });

    page.drawText(`${data[0].transactionNumber}`, {
      x: page.getWidth() - 100,
      y: page.getHeight() - 30,
      size: titleFontSize,
      font,
    });

    page.drawText(`To: ${data[0].name || "Cash"}`, {
      x: 15,
      y: page.getHeight() - 50,
      size: textFontSize,
      font,
    });
    page.drawText(`Reference Invoice: ${data[0].refInvoice || ""}`, {
      x: 15,
      y: page.getHeight() - 65,
      size: textFontSize,
      font,
    });
    page.drawText(`Delivery Note: ${data[0].deliveryNoteNumber || ""}`, {
      x: 15,
      y: page.getHeight() - 80,
      size: textFontSize,
      font,
    });
    page.drawText(moment(data[0].transactionDate).format("DD MMM YYYY"), {
      x: page.getWidth() - 100,
      y: page.getHeight() - 80,
      size: textFontSize,
      font,
    });

    // Draw table headers
    const tableTop = page.getHeight() - 110;
    let yPos = tableTop;
    const tableHeaderX = 15;

    page.drawRectangle({
      x: 1,
      y: yPos - 3,
      width: width - 1,
      height: 15,
      borderColor: rgb(0, 0, 0),
      borderWidth: 1.5,
      color: rgb(0, 0, 0),
    });

    page.drawText("Description", {
      x: tableHeaderX + 30,
      y: yPos,
      size: subtitleFontSize,
      font,
      color: rgb(1, 1, 1),
    });
    page.drawText("Amount", {
      x: page.getWidth() - 70,
      y: yPos,
      size: subtitleFontSize,
      font,
      color: rgb(1, 1, 1),
    });

    // Draw table rows with data
    yPos -= 15;
    const maxRows = 10;
    const tableRows = data.slice(0, maxRows);
    while (tableRows.length < maxRows) {
      tableRows.push(["", ""]); // Push empty rows until we reach 10
    }

    page.drawLine({
      start: { x: width - 80, y: tableTop },
      end: { x: width - 80, y: tableTop - 150 },
      color: rgb(0, 0, 0),
      thickness: 1,
      dashArray: [1],
    });

    tableRows.forEach(
      (item: { qty: number; description: string; price: number }) => {
        const description = `${item.description}  (${item.qty})`;
        const amount = numberWithCommas(item.qty * item.price);

        // Calculate the width of the amount text
        const amountWidth = font.widthOfTextAtSize(amount, textFontSize);

        // Calculate the x-coordinate for right alignment
        const amountX = width - amountWidth - 20; // Adjust '70' for right padding from the page edge

        page.drawText(item.description === undefined ? "" : description, {
          x: tableHeaderX,
          y: yPos,
          size: textFontSize,
          font,
          color: rgb(0, 0, 0),
        });
        page.drawText(item.description === undefined ? "" : amount, {
          x: amountX,
          y: yPos,
          size: textFontSize,
          font,
          color: rgb(0, 0, 0),
        });
        page.drawLine({
          start: { x: 3, y: yPos - 3 },
          end: { x: width - 3, y: yPos - 3 },
          color: rgb(0, 0, 0),
          thickness: 1,
          dashArray: [1],
        });
        yPos -= 15;
      }
    );

    // Add total
    const total = `TOTAL: ${numberWithCommas(data[0].totalAmount)}`;
    page.drawText(total, {
      x: page.getWidth() - 200,
      y: yPos - 40,
      size: titleFontSize,
      font,
      color: rgb(0, 0, 0),
    });

    // Add signatures if available
    if (supplierSignature) {
      const supplierSigBytes = await fetch(supplierSignature).then((res) =>
        res.arrayBuffer()
      );
      const supplierSigImage = await pdfDoc.embedPng(supplierSigBytes);
      page.drawImage(supplierSigImage, {
        x: 100,
        y: 50,
        width: 50,
        height: 20,
      });
    }

    if (staffSignature) {
      const staffSigBytes = await fetch(staffSignature).then((res) =>
        res.arrayBuffer()
      );
      const staffSigImage = await pdfDoc.embedPng(staffSigBytes);
      page.drawImage(staffSigImage, {
        x: page.getWidth() - 100,
        y: 50,
        width: 50,
        height: 20,
      });
    }

    page.drawText("Signature: ", {
      x: 20,
      y: 50,
      size: subtitleFontSize,
      font,
      color: rgb(0, 0, 0),
    });

    page.drawText("Authorized: ", {
      x: page.getWidth() - 200,
      y: 50,
      size: subtitleFontSize,
      font,
      color: rgb(0, 0, 0),
    });

    // Download PDF
    const pdfBytes = await pdfDoc.save();
    const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });
    // saveAs(pdfBlob, 'voucher.pdf');
    const pdfUrl = URL.createObjectURL(pdfBlob);

    // const link = document.createElement('a');
    // link.href = pdfUrl;
    // link.download = 'pdf-lib-example.pdf';
    // link.click();

    setPdfUrl(pdfUrl);
    onOpen();
    setIsLoading(false);
    // saveAs(pdfBlob, 'voucher.pdf');
    // console.log(
    //   `PDF Dimensions: Width = ${doc.internal.pageSize.getWidth()} mm, Height = ${doc.internal.pageSize.getHeight()} mm`
    // );
    // setIsLoading(false);
  };

  // Actually downloads the voucher
  const handleDownload = () => {
    if (pdfUrl) {
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = `${orders[0].transactionNumber} - ${orders[0].name}.pdf`;
      link.click();
    }
  };

  // Get signature of user
  const getStaffSignature = async () => {
    const headers = await getHeadersForRequest();
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_TEST_API_URL}/auth/signature`,
        { headers }
      );
      // console.log(response.data.signature);
      if (response.status === 200) {
        setStaffSignature(response.data.signature);
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  // Get signature of supplier
  const getSupplierSignature = async (id: number) => {
    setIsLoading(true);
    const oldHeaders = await getHeadersForRequest();
    const newHeaders = {
      id: id,
    };
    const headers = { ...oldHeaders, ...newHeaders };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_TEST_API_URL}/transactions/signature`,
        { headers }
      );
      console.log(response.data.signature);
      if (response.status === 200) {
        setSupplierSignature(response.data.signature);
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  //add image to image array after cropping
  const updateImageList = () => {
    if (croppedImageUrl !== null) {
      // Add the new image to the array of images
      setImages((prevImages) => [...prevImages, croppedImageUrl]);
      setCroppedImageUrl(null);
      setImageSrc(null);
    }
  };

  //add image to image array and open camera/dialog for next capture/scan
  const updateImageListAndAdd = () => {
    if (croppedImageUrl !== null) {
      // Add the new image to the array of images
      setImages((prevImages) => [...prevImages, croppedImageUrl]);
      setCroppedImageUrl(null);
      setImageSrc(null);
      openCamera();
    }
  };

  // Function to generate PDF from the captured images for additional resources for the expense
  const generateAdditionalResourcePdf = async () => {
    if (images.length === 0) {
      alert("Please capture at least one image.");
      return;
    }

    const pdfDoc = await PDFDocument.create();

    images.forEach(async (image, index) => {
      const imgBytes = await fetch(image).then((res) => res.arrayBuffer());
      const img = await pdfDoc.embedJpg(imgBytes); // Use embedPng for PNG images

      // Get image dimensions
      const imgWidth = img.width;
      const imgHeight = img.height;
      console.log("index", index, "w:", imgWidth, "h", imgHeight);
      // Add a new page with the same dimensions as the image
      const page = pdfDoc.addPage([imgWidth, imgHeight]);

      // Draw the image on the page
      page.drawImage(img, {
        x: 0,
        y: 0,
        width: imgWidth,
        height: imgHeight,
      });

      // After adding the last image, save the PDF and upload it
      if (index === images.length - 1) {
        // pdfBlob = pdfDoc.output("blob"); // Output the PDF as a blob

        const pdfBytes = await pdfDoc.save();
        const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });

        // uploadPdf(pdfBlob); // Upload the generated PDF

        const formData = new FormData();
        formData.append(
          "file",
          pdfBlob,
          orders[0].transactionNumber.toString()
        );
        const response = await uploadToGoogle(formData);

        if (response === 1) {
          setImages([]);
          checkFileExists();
          setImageToView(null);
        }
      }
    });
  };

  // get PDF file from user machine and delete all images from images array
  const handlePdfUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setPdfFile(event.target.files[0]); // Store selected PDF file
      setImages([]); // Clear any captured images if a PDF is uploaded
    }
  };

  const uploadSelectedPdfFile = async () => {
    if (pdfFile !== null) {
      const pdfBlob = new Blob([pdfFile], { type: "application/pdf" });
      const formData = new FormData();
      formData.append("file", pdfBlob, orders[0].transactionNumber.toString());
      const response = await uploadToGoogle(formData);

      if (response === 1) {
        setImages([]);
        checkFileExists();
        setPdfFile(null);
        setIsPhotoListModalOpen(false);
      }
    } else {
      toast({
        title: "File not found, please re-upload from local storage",
        duration: 3000,
        status: "warning",
      });
    }
  };

  // upload PDF file to API that will push it to GOOGLE DRIVE using the transaction ID as the file name
  const uploadToGoogle = async (formData: FormData) => {
    setIsUploading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_TEST_API_URL}/google/upload-pdf`,
        formData,
        {
          headers: {
            fileName: orders[0].transactionNumber,
          },
        }
      );
      console.log(response);
      // alert("PDF uploaded successfully.");
      if (response.data.fileId) {
        toast({
          title: "Upload Successful",
          duration: 3000,
          status: "success",
        });
        return 1;
      }
    } catch (error) {
      console.error("Error uploading PDF:", error);
      setErr(JSON.stringify(error));
      toast({
        title: JSON.stringify(error),
        duration: 3000,
        status: "error",
      });
    } finally {
      setIsUploading(false);
    }
  };

  // Function to trigger the hidden file input
  const openCamera = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Programmatically trigger the file input's click
      setIsPhotoListModalOpen(true);
    }
  };

  // Function to trigger the hidden file input for uploading a PDF
  const openPdfBrowser = () => {
    if (pdfInputRef.current) {
      pdfInputRef.current.click(); // Programmatically trigger the file input's click for PDF upload
    }
  };

  // Function to delete an image by index
  const handleDeleteImage = (index: number) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index)); // Remove the image at the specified index
    setImageToView(null);
  };

  const handleOpenSupplierModal = async () => {
    const suppliers = await getSupplierList();

    if (suppliers.success === 1) {
      setShowSupplierSelectionModal(true);
      setSuppliersList(suppliers.data);
    } else {
      toast({
        title: "Failed to load suppliers list, try again later",
        duration: 3000,
        status: "error",
      });
    }
  };

  const handleUpdateSupplier = async () => {
    try {
      const data = {
        supplierId: selectedSupplier,
        transactionId: orders[0].id,
      };

      // if (data.deliveryNoteNumber === "" && data.physicalDeliveryNote) {
      //   toast({ title: "Invalid Delivery Note Number" });
      //   return;
      // }

      const headers = await getHeadersForRequest();
      await axios
        .post(
          `${process.env.REACT_APP_TEST_API_URL}/transactions/updateSupplier`,
          {
            data,
          },
          { headers }
        )
        .then((response) => {
          console.log(response.data);
          toast({
            title: "Supplier updated successfully",
            duration: 3000,
            status: "success",
          });
          setShowSupplierSelectionModal(false);
          getOrderDetails();
        })
        .catch((error) => {
          console.error(error);
          toast({
            title: "Error updating supplier",
            duration: 3000,
            status: "error",
          });
        });
    } catch (error) {}
  };

  return (
    <Stack>
      <Stack>
        {ID !== "" ? null : (
          <Stack position="sticky" top={0} zIndex={1} width="full">
            <Nav pageName={"Expense Details"} />
          </Stack>
        )}
        <Stack>
          <Stack px={1}>
            {isLoading ? (
              <Center h={600}>
                <Loading />
              </Center>
            ) : (
              <>
                <Stack p={1}>
                  {orders.length > 0 && (
                    <Stack borderWidth={2} borderRadius={5} pb={2}>
                      <Stack
                        alignItems={"center"}
                        pt={2}
                        position={"absolute"}
                        right={0}
                        left={0}
                      >
                        <Button
                          colorScheme="green"
                          onClick={() => {
                            // downloadVoucher(orders);
                            downloadVoucherPDFLIB(orders);
                          }}
                          // disabled={!supplierSignature || !staffSignature}
                        >
                          Voucher
                        </Button>
                      </Stack>
                      <HStack
                        alignItems={"flex-start"}
                        justifyContent={"space-between"}
                        p={1}
                        pl={3}
                      >
                        <Stack textAlign={"left"}>
                          <Text fontSize="2xl" fontFamily="">
                            PO# {orders[0].transactionNumber}
                          </Text>
                          {orders[0].refInvoice && (
                            <Text>Inv Ref: {orders[0].refInvoice}</Text>
                          )}
                          {orders[0].deliveryNoteNumber && (
                            <Text>
                              Delivery Note #{orders[0].deliveryNoteNumber}
                            </Text>
                          )}

                          <Text
                            fontSize="lg"
                            fontFamily=""
                            mb={-2}
                            py={5}
                            fontWeight={"bolder"}
                            color={orders[0].paymentStatus ? "green" : "red"}
                          >
                            {orders[0].paymentStatus ? "PAID" : "NOT PAID"}
                          </Text>
                          <Text fontSize="lg" fontFamily="" mb={-2}>
                            Supplier
                          </Text>
                          <Text fontSize="lg" fontFamily="">
                            {orders[0].name ? orders[0].name : "Cash"}
                          </Text>
                          <Text fontSize="lg" fontFamily="">
                            {orders[0].tin}
                          </Text>

                          {Cookies.get("emailAddress") ===
                            "keinthraghav@gmail.com" && (
                            <Button onClick={handleOpenSupplierModal}>
                              Change
                            </Button>
                          )}
                        </Stack>

                        <Stack>
                          <img
                            src={
                              Cookies.get("businessName") === "Keinth's Food"
                                ? logo
                                : logo2
                            }
                            style={{ height: 200, backgroundColor: "white" }}
                          />

                          <Text
                            mb={-2}
                            fontSize="lg"
                            fontFamily=""
                            textAlign={"right"}
                          >
                            PO Date
                          </Text>
                          <Text fontSize="lg" fontFamily="" textAlign={"right"}>
                            {moment(orders[0].invoiceDate).format(
                              "DD MMM YYYY"
                            )}
                          </Text>
                        </Stack>
                      </HStack>

                      <Stack
                        flexDir="row"
                        alignItems="center"
                        borderBottomColor={"black"}
                        borderBottomWidth={2}
                        borderTopColor={"black"}
                        borderTopWidth={2}
                      >
                        <Stack
                          flex={1}
                          alignItems={"center"}
                          borderRightWidth={1}
                          borderRightColor={"black"}
                        >
                          <Text fontSize={"lg"}>QTY</Text>
                        </Stack>

                        <Stack
                          pl={3}
                          style={{
                            flex: 6,
                          }}
                        >
                          <Text fontSize={"lg"}>DESCRIPTION</Text>
                        </Stack>
                        <Stack
                          style={{
                            flex: 2,
                            alignItems: "center",
                          }}
                        >
                          <Text fontSize={"lg"}>AMOUNT</Text>
                        </Stack>
                      </Stack>

                      {/* TO DO: IMPLEMENT THE ONE FROM NATIVE BASE */}

                      {orders.map((item, key) => (
                        <Stack
                          flexDir="row"
                          py={2}
                          alignItems="center"
                          borderBottomColor={"black"}
                          borderBottomWidth={2}
                        >
                          <Stack
                            flex={1}
                            alignItems={"center"}
                            borderRightWidth={1}
                            borderRightColor={"black"}
                          >
                            <Text fontSize={"2xl"}>{item.qty}</Text>
                          </Stack>

                          <Stack
                            pl={3}
                            style={{
                              textAlign: "left",
                              flex: 6,
                            }}
                          >
                            <Text
                              fontSize={"xl"}
                              // color={"white"}
                            >
                              {item.description}
                            </Text>
                          </Stack>
                          <Stack
                            style={{
                              flex: 2,
                              alignItems: "center",
                            }}
                          >
                            <Text fontSize={"xl"}>
                              {numberWithCommas(
                                parseInt(item.price) * parseInt(item.qty)
                              )}
                            </Text>
                          </Stack>
                        </Stack>
                      ))}

                      {/* END OF LIST */}

                      <Stack alignItems={"flex-end"} pr={3} py={2}>
                        <Text fontSize="3xl" fontFamily="">
                          TOTAL{"     "}
                          {numberWithCommas(orders[0].totalAmount)}
                        </Text>
                        {orders[0].signature === null && (
                          <Button
                            colorScheme="blue"
                            onClick={() => openSignatureModal()}
                          >
                            Add Signature
                          </Button>
                        )}
                      </Stack>

                      {checkingForResources ? (
                        <Loading />
                      ) : (
                        <>
                          {" "}
                          {resourceFound ? (
                            <Text textAlign={"center"} fontSize={"xl"}>
                              Additional Resources Found:
                            </Text>
                          ) : (
                            <Text textAlign={"center"} fontSize={"xl"}>
                              Additional Resources Not Found:
                            </Text>
                          )}
                          {resourceFound ? (
                            <HStack
                              alignItems={"center"}
                              justifyContent={"space-evenly"}
                              // pt={2}
                              // position={"absolute"}
                              // right={10}
                              // bottom={10}
                            >
                              <Button
                                colorScheme="blue"
                                onClick={() => {
                                  viewResource();
                                }}
                              >
                                Preview
                              </Button>
                              <Button
                                colorScheme="blue"
                                onClick={() => {
                                  downloadResources();
                                }}
                              >
                                Download
                              </Button>
                            </HStack>
                          ) : (
                            <Stack alignItems={"center"}>
                              {/* Hidden input to capture images from the device camera */}
                              <input
                                ref={fileInputRef}
                                type="file"
                                accept="image/*"
                                capture="user"
                                onChange={handleFileChange}
                                style={{ display: "none" }} // Hide the input element
                              />

                              {/* Hidden input to upload a PDF */}
                              <input
                                ref={pdfInputRef}
                                type="file"
                                accept="application/pdf"
                                onChange={handlePdfUpload}
                                style={{ display: "none" }} // Hide the input element
                              />

                              <HStack>
                                <Button
                                  onClick={
                                    images.length > 0
                                      ? () => setIsPhotoListModalOpen(true)
                                      : openCamera
                                  }
                                  colorScheme="blue"
                                >
                                  {images.length > 0
                                    ? "Continue Scanning"
                                    : "Scan"}
                                </Button>

                                {images.length === 0 && (
                                  <Button onClick={openPdfBrowser}>
                                    Browse
                                  </Button>
                                )}
                              </HStack>
                            </Stack>
                          )}
                          {pdfFile && (
                            <Center p={5}>
                              <Text pr={3}>Uploaded PDF: {pdfFile.name}</Text>
                              <Button
                                colorScheme="green"
                                isLoading={isUploading}
                                onClick={() => uploadSelectedPdfFile()}
                              >
                                Upload
                              </Button>
                            </Center>
                          )}
                          <Text>Err: {err}</Text>
                        </>
                      )}
                    </Stack>
                  )}
                </Stack>

                {/* Resource Photo Scan Modal */}
                <Modal
                  isOpen={isPhotoListModalOpen}
                  onClose={() => setIsPhotoListModalOpen(false)}
                  size="xl"
                >
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader>Additional Resources</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      {imageSrc && (
                        <>
                          <ReactCrop
                            crop={crop}
                            onChange={(newCrop) => setCrop(newCrop)} // Update crop state on change
                            onComplete={getCroppedImg} // Callback when cropping is done
                            ruleOfThirds // Optional grid for easier cropping
                          >
                            {/* Apply the image source to the img tag inside ReactCrop */}
                            <img
                              ref={imageRef} // Attach the image reference for cropping
                              src={imageSrc}
                              alt="Source"
                              style={{ maxWidth: "100%" }}
                            />
                          </ReactCrop>
                          <HStack justifyContent={"flex-end"}>
                            <Button
                              colorScheme="blue"
                              onClick={() => updateImageList()}
                            >
                              Save
                            </Button>
                            <Stack px={2}></Stack>
                            <Button
                              colorScheme="blue"
                              onClick={() => updateImageListAndAdd()}
                            >
                              Save & Add
                            </Button>
                          </HStack>
                        </>
                      )}

                      {imageToView !== null && (
                        <Stack alignItems={"center"} position={"relative"}>
                          <Button
                            position={"absolute"}
                            right={0}
                            top={0}
                            onClick={() => {
                              handleDeleteImage(selectedIndex);
                            }}
                            variant={"solid"}
                            mr={2}
                            color="red.600"
                          >
                            <DeleteIcon w={6} h={6} />
                          </Button>
                          <Img
                            maxH={"70vh"}
                            w={"fit-content"}
                            src={imageToView}
                          />
                        </Stack>
                      )}
                      {images.length > 0 && (
                        <HStack
                          scrollBehavior={"smooth"}
                          overflowX="scroll" // Enable horizontal scrolling
                          whiteSpace="nowrap" // Prevent items from wrapping to the next line
                        >
                          {images.map((image, index) => (
                            <Img
                              onClick={() => {
                                setImageSrc(null);
                                setImageToView(image);
                                setSelectedIndex(index);
                              }}
                              maxH={
                                imageSrc === null && imageToView === null
                                  ? "70vh"
                                  : "50"
                              }
                              w={
                                imageSrc === null && imageToView === null
                                  ? "fit-content"
                                  : "50"
                              }
                              key={index}
                              src={image}
                              alt={`Captured ${index}`}
                              style={{
                                maxWidth: "100%",
                                margin: "10px 0",
                                display: "block",
                              }}
                            />
                          ))}
                        </HStack>
                      )}
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        variant="ghost"
                        onClick={() => setIsPhotoListModalOpen(false)}
                      >
                        Close
                      </Button>
                      {imageSrc === null && (
                        <Button colorScheme="blue" mr={3} onClick={openCamera}>
                          Add
                        </Button>
                      )}

                      {images.length > 0 && (
                        <Button
                          isLoading={isUploading}
                          colorScheme="green"
                          mr={3}
                          onClick={() => {
                            generateAdditionalResourcePdf();
                          }}
                        >
                          Upload
                        </Button>
                      )}
                    </ModalFooter>
                  </ModalContent>
                </Modal>

                {/* Preview Modal */}
                <Modal isOpen={isOpen} onClose={onClose} size="xl">
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader>PDF Preview</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      {pdfUrl && (
                        <iframe
                          ref={iframeRef}
                          src={pdfUrl}
                          width="100%"
                          height="400px"
                        />
                      )}
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        colorScheme="blue"
                        mr={3}
                        onClick={handleDownload}
                      >
                        Download PDF
                      </Button>
                      <Button variant="ghost" onClick={onClose}>
                        Close
                      </Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>

                {/* Signature Modal */}
                <Modal
                  isOpen={isSignatureModalOpen}
                  onClose={() => setIsSignatureModalOpen(false)}
                >
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader>Recipient</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      <Stack
                        borderWidth={1}
                        borderColor={colorMode === "light" ? "black" : "white"}
                        borderRadius={10}
                        bg={"white"}
                        w={"100%"}
                      >
                        <SignatureCanvas
                          ref={sigCanvas}
                          penColor="black"
                          canvasProps={{ height: 500, className: "sigCanvas" }}
                        />
                      </Stack>

                      <ModalFooter>
                        <Button onClick={clearSignature} mr={3}>
                          Clear
                        </Button>
                        <Button
                          isLoading={modalIsLoading}
                          onClick={saveSignature}
                        >
                          Save Signature
                        </Button>
                      </ModalFooter>
                    </ModalBody>
                  </ModalContent>
                </Modal>

                {/* Change Supplier Modal */}
                <Modal
                  isCentered
                  isOpen={showSupplierSelectionModal}
                  onClose={() => setShowSupplierSelectionModal(false)}
                  size="xl"
                >
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader>Update Supplier</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      <Text>Pick A Supplier </Text>
                      <Select
                        // selectedValue={selectedSupplier}
                        onChange={(e: any) =>
                          setSelectedSupplier(e.target.value)
                        }
                      >
                        <option value={""}>Cash</option>

                        {suppliersList.map((supplier) => (
                          <option value={supplier.id.toString()}>
                            {supplier.name}
                          </option>
                        ))}
                      </Select>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        colorScheme="blue"
                        mr={3}
                        onClick={handleUpdateSupplier}
                      >
                        Update
                      </Button>
                      <Button
                        variant="ghost"
                        onClick={() => setShowSupplierSelectionModal(false)}
                      >
                        Close
                      </Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>
              </>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ExpenseDetailsScreen;
