import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { useColorMode } from "@chakra-ui/react";

const MuiThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { colorMode } = useColorMode();

  // Dynamically create the Material-UI theme based on Chakra's color mode
  const muiTheme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: colorMode, // Chakra's 'light' or 'dark' mode
          primary: {
            main: "#3182CE", // Chakra UI's blue color
          },
          secondary: {
            main: "#2D3748", // Chakra UI's gray color
          },
        },
        // typography: {
        //   fontFamily: "Inter, sans-serif", // Chakra UI's default font
        //   fontWeightBold: 700, // Define fontWeightBold
        // },
        shadows: Array(25).fill("none") as any, // MUI expects shadows[1] to be defined
      }),
    [colorMode] // Recreate theme when colorMode changes
  );

  return (
    <ThemeProvider theme={muiTheme}>
      <CssBaseline /> {/* Apply MUI baseline styles */}
      {children}
    </ThemeProvider>
  );
};

export default MuiThemeProvider;
