import { ReactNode, useState } from "react";
import {
  Box,
  Flex,
  Avatar,
  Link,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
  useColorMode,
  Center,
  Text,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ButtonGroup,
  ModalFooter,
  useBreakpointValue
} from "@chakra-ui/react";
import { ArrowBackIcon, MoonIcon, SunIcon } from "@chakra-ui/icons";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
// import logo from "../resources/images/logo.jpg";
import { getHeadersForRequest } from "../utils/functions";
import axios from "axios";
import useCustomTranslation from "../hooks/useCustomTranslation";

const NavLink = ({ children }: { children: ReactNode }) => (
  <Link
    px={2}
    py={1}
    rounded={"md"}
    _hover={{
      textDecoration: "none",
      bg: useColorModeValue("gray.200", "gray.700"),
    }}
    href={"#"}
  >
    {children}
  </Link>
);

type navbarProps = {
  pageName: string;
};

const Nav: React.FC<navbarProps> = ({ pageName }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast();

  const { t, changeLanguage } = useCustomTranslation();

  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  const location = useLocation();
  const navigate = useNavigate();
  const handleLogout = async () => {
    setIsLoading(true);
    try {
      const headers = await getHeadersForRequest();
      const response = await axios.get(
        `${process.env.REACT_APP_TEST_API_URL}/auth/logout`,
        {
          headers,
        }
      );
      console.log(response.data);
      if (response.data.errorCode === 0) {
        setIsLoading(false);
        Cookies.remove("userName");
        Cookies.remove("userToken");
        Cookies.remove("emailAddress");
        Cookies.remove("deviceId");
        toast({ title: "Successfully Logged Out", status: "success" });
        setShowConfirmationModal(false);
        navigate("/login");
      } else {
        toast({ title: "Failed to logout", status: "error" });
        setIsLoading(false);
        setShowConfirmationModal(false);
      }
    } catch (error) {
      console.error("Error fetching customer purchases data:", error);
      toast({ title: "Failed to logout", status: "error" });
      setIsLoading(false);
      setShowConfirmationModal(false);
    }
  };

  const isDashboard = location.pathname === "/dashboard";
  return (
    <Stack>
      <Box bg={useColorModeValue("gray.100", "gray.900")} px={4}>
        <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
          {!isDashboard && (
            <Button onClick={() => navigate(-1)} variant={"ghost"} mr={2}>
              <ArrowBackIcon w={6} h={6} />
            </Button>
          )}

          <Button
            onClick={() => navigate("/dashboard")}
            fontSize={"2xl"}
            fontWeight={"bold"}
            variant={"ghost"}
          >
            {/* {Cookies.get("businessName")} */}
            {pageName}
          </Button>

          <Flex alignItems={"center"}>
            <Stack direction={"row"} spacing={7}>
              {/* <Menu>
                <MenuButton
                  as={Button}
                  rounded={"full"}
                  variant={"link"}
                  cursor={"pointer"}
                  minW={0}
                >
                  {t("language")}
                </MenuButton>
                <MenuList alignItems={"center"} justifyContent={"center"}>
                  <MenuItem onClick={() => changeLanguage("en")}>
                    <Text>English</Text>
                  </MenuItem>
                  <MenuDivider />
                  <MenuItem onClick={() => changeLanguage("sw")}>
                    <Text>Kiswahili</Text>
                  </MenuItem>
                  <MenuDivider />
                  <MenuItem onClick={() => changeLanguage("pa")}>
                    <Text>ਪੰਜਾਬੀ</Text>
                  </MenuItem>
                </MenuList>
              </Menu> */}

              {!isSmallScreen && (
                <Button onClick={toggleColorMode}>
                  {colorMode === "light" ? <MoonIcon /> : <SunIcon />}
                </Button>
              )}
              <Menu>
                <MenuButton
                  as={Button}
                  rounded={"full"}
                  variant={"link"}
                  cursor={"pointer"}
                  minW={0}
                >
                  <Avatar
                    size={"sm"}
                    src={localStorage.getItem("profilePic") || undefined}
                  />
                </MenuButton>
                <MenuList alignItems={"center"} justifyContent={"center"}>
                  <br />
                  <Center>
                    <Avatar
                      size={"2xl"}
                      src={localStorage.getItem("profilePic") || undefined}
                    />
                  </Center>
                  <br />
                  <Center>
                    <Text fontSize={"2xl"}>{Cookies.get("userName")}</Text>
                  </Center>
                  <br />
                  <MenuDivider />
                  <MenuItem onClick={() => navigate("/ChangePassword")}>
                    {t("ChangePassword")}
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/Logins")}>
                    {t("ShowLogins")}
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/Settings")}>
                    {t("Settings")}
                  </MenuItem>
                  {/* <MenuItem onClick={() => navigate("/PredictionTest")}>
                    {t("Prediction")}
                  </MenuItem> */}
                  <MenuItem justifyContent={"center"}>
                    <Button
                      as={"a"}
                      fontWeight={600}
                      color={"white"}
                      bg={"red.500"}
                      href={"#"}
                      _hover={{
                        bg: "red.400",
                      }}
                      isLoading={isLoading}
                      onClick={() => setShowConfirmationModal(true)}
                    >
                      {t("Logout")}
                    </Button>
                  </MenuItem>
                </MenuList>
              </Menu>
            </Stack>
            <Modal
              isCentered
              closeOnOverlayClick={false}
              isOpen={showConfirmationModal}
              onClose={() => setShowConfirmationModal(false)}
            >
              <ModalOverlay
                bg="blackAlpha.300"
                backdropFilter="blur(10px) hue-rotate(90deg)"
              />
              <ModalContent maxWidth="350">
                <ModalCloseButton />
                <ModalHeader> {t("Logout")}?</ModalHeader>
                <ModalBody>{t("Confirm if you want to logout")}</ModalBody>
                <ModalFooter>
                  <ButtonGroup>
                    <Button
                      variant="ghost"
                      colorScheme="blueGray"
                      onClick={() => {
                        setShowConfirmationModal(false);
                      }}
                    >
                      {t("Cancel")}
                    </Button>
                    <Button
                      colorScheme="red"
                      isLoading={isLoading}
                      onClick={handleLogout}
                    >
                      {t("Logout")}
                    </Button>
                  </ButtonGroup>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Flex>
        </Flex>
      </Box>
    </Stack>
  );
};

export default Nav;
