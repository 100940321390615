import React from "react";
import Nav from "../components/navbar";
import { Text, Stack } from "@chakra-ui/react";
import Bar from "../components/analytics/ThisYearBar";
import OldBar from "../components/analytics/LastYearBar";
import CustomerPurchasesChart from "../components/analytics/CustomersPurchase";
import LastYearCustomerPurchasesChart from "../components/analytics/LastYearCustomersPurchase";
import useCustomTranslation from "../hooks/useCustomTranslation";
import CustomerPurchasesPieChart from "../components/analytics/PieTemplate";

const Analytics: React.FC = () => {
  const { t, changeLanguage } = useCustomTranslation();

  return (
    <>
      <Stack position="sticky" top={0} zIndex={1} width="full">
        <Nav pageName={"Analytics"}/>
      </Stack>
      <Stack px={10} py={5}>
        <Text>{t("Current year performance")}</Text>
        <Bar />
        <br />
        <Text>{t("Last year performance")}</Text>
        <OldBar />
        <br />
        <Text>{t("Current Year Customer Orders")}</Text>
        <CustomerPurchasesChart />
        <br />
        <Text>{t("Last Year Customer Orders")}</Text>
        <LastYearCustomerPurchasesChart />
        <br />
        <Text>{t("This Year Customer Orders Pie")}</Text>
        <CustomerPurchasesPieChart />
      </Stack>
    </>
  );
};

export default Analytics;
