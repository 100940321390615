import { Card, Skeleton, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { AxisOptions, Chart } from "react-charts";
import axios from "axios";
import { getHeadersForRequest, numberWithCommas } from "../../utils/functions";
import useCustomTranslation from "../../hooks/useCustomTranslation";

type DataPoint = {
  primary: string; // Month names
  secondary: number;
};

type Series = {
  label: string;
  data: DataPoint[];
};

type CustomerPurchasesResponse = {
  customerPurchases: { [key: string]: number[] };
};

export default function CustomerPurchasesChart() {
  const [data, setData] = useState<Series[]>([]);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [highlightedSeries, setHighlightedSeries] = useState<string | null>(
    null
  ); // Track which line is highlighted
  const [selectedCustomerTotal, setSelectedCustomerTotal] = useState<{
    label: string;
    total: number;
  } | null>(null);

  useEffect(() => {
    const fetchCustomerPurchases = async () => {
      try {
        const headers = await getHeadersForRequest();
        const response = await axios.get<CustomerPurchasesResponse>(
          `${process.env.REACT_APP_TEST_API_URL}/analytics/monthly/customer-purchases`,
          {
            headers,
          }
        );
        const processedData = processCustomerPurchases(response.data);
        setData(processedData);
        setIsLoaded(true);
      } catch (error) {
        console.error("Error fetching customer purchases data:", error);
      }
    };

    fetchCustomerPurchases();
  }, []);

  const primaryAxis = React.useMemo<AxisOptions<DataPoint>>(
    () => ({
      getValue: (datum) => datum.primary,
      scaleType: "band",
      paddingInner: 0, // Adjust inner padding
      paddingOuter: 0, // Remove outer padding
      tickFormat: (value: any) => value.trim(), // Ensure tick labels are clean
    }),
    []
  );

  const secondaryAxes = React.useMemo<AxisOptions<DataPoint>[]>(
    () => [
      {
        getValue: (datum) => datum.secondary,
        scaleType: "linear",
        elementType: "line",
      },
    ],
    []
  );

  const { t } = useCustomTranslation();

  const processCustomerPurchases = (
    data: CustomerPurchasesResponse
  ): Series[] => {
    const months = [
      t("January").trim(),
      t("February").trim(),
      t("March").trim(),
      t("April").trim(),
      t("May").trim(),
      t("June").trim(),
      t("July").trim(),
      t("August").trim(),
      t("September").trim(),
      t("October").trim(),
      t("November").trim(),
      t("December").trim(),
    ];

    return Object.entries(data.customerPurchases).map(
      ([customerKey, monthlyData]) => {
        const [customerId, customerName] = customerKey.split(":");
        return {
          label: `${t("PurchasesBy")} ${customerName}`.trim(),
          data: monthlyData.map((value, index) => ({
            primary: months[index], // Cleaned month names
            secondary: value,
          })),
        };
      }
    );
  };

  const handleSeriesClick = (seriesLabel: string) => {
    if (seriesLabel) {
      setHighlightedSeries((prev) =>
        prev === seriesLabel ? null : seriesLabel
      );
    }
    const series = data.find((s) => s.label === seriesLabel);
    if (series) {
      const total = series.data.reduce(
        (sum, point) => sum + point.secondary,
        0
      );
      setSelectedCustomerTotal({ label: seriesLabel, total });
    }
  };

  const processedData = highlightedSeries
    ? data.filter((series) => series.label === highlightedSeries)
    : data;

  return (
    <Skeleton isLoaded={isLoaded} fadeDuration={4}>
      <Card>
        <Stack w="100%" h={500}>
          <Chart
            options={{
              data: processedData,
              primaryAxis,
              secondaryAxes,
              dark: useColorModeValue(false, true),
              getSeriesStyle: (series) => ({
                opacity:
                  highlightedSeries && series.label !== highlightedSeries
                    ? 0.3
                    : 1, // Dim unselected lines
                cursor: "pointer",
              }),
              onClickDatum: (datum) => {
                if (datum && datum.seriesLabel) {
                  handleSeriesClick(datum.seriesLabel);
                }
              },
            }}
          />
        </Stack>
        {selectedCustomerTotal && (
          <Text fontSize="lg" mt={4}>
            {t("Total Purchases for")} {selectedCustomerTotal.label}:{" "}
            <strong>{numberWithCommas(selectedCustomerTotal.total)}</strong>
          </Text>
        )}
      </Card>
    </Skeleton>
  );
}
