import {
  Button,
  ButtonGroup,
  Center,
  Checkbox,
  CheckboxGroup,
  HStack,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getHeadersForRequest } from "../utils/functions";
import axios from "axios";
import Nav from "../components/navbar";
import { Loading } from "../components/Loading";
import { TransactionCard } from "../components/TransactionCard";
import {
  FaEdit,
  FaEye,
  FaPenNib,
  FaPencilAlt,
  FaRegEdit,
  FaTrash,
} from "react-icons/fa";
import Cookies from "js-cookie";
import { t } from "i18next";
import useCustomTranslation from "../hooks/useCustomTranslation";
import * as XLSX from "xlsx";

interface Transaction {
  id: number;
  transactionNumber: string;
  invoiceDate: Date;
  paymentStatus: boolean;
  deliveryStatus: boolean;
  physicalDeliveryNote: boolean;
  deliveryNoteNumber: string;
  traReceiptNumber: string;
  traTime: string;
  accountName: string;
}

type TransactionsList = {
  name: string;
  id: number;
  transactionNumber: string;
  invoiceDate: Date;
  totalAmount: number;
  paymentStatus: boolean;
  deliveryStatus: boolean;
  transactionType: number;
  physicalDeliveryNote: boolean;
  deliveryNoteNumber: string;
  isVoid: boolean;
  traReceiptNumber: string;
  traTime: string;
  accountName: string;
};

const CustomerOrdersScreen = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
  const [modalIsLoading, setModalIsLoading] = useState<boolean>(false);
  const [transactions, setTransactions] = useState<TransactionsList[]>([]);
  const [groupValues, setGroupValues] = useState<string[]>([]);
  const [deliveryNoteNumber, setDeliveryNoteNumber] = useState<string>("");
  const [transactionId, setTransactionId] = useState<number>(0);
  const [traReceiptNumber, setTraReceiptNumber] = useState<string>("");
  const [traReceiptTime, setTraReceiptTime] = useState<string>("");

  const today = new Date();
  today.setDate(today.getDate() - 90);

  const [startDate, setStartDate] = useState<string>(today.toISOString());
  const toast = useToast();
  const navigate = useNavigate();

  const { t, changeLanguage } = useCustomTranslation();

  const { customerId, customerName } = useParams<{
    customerId: string;
    customerName: string;
  }>();

  useEffect(() => {
    setIsLoading(true);
    getAllTransactions();
  }, []);

  const navigateToOrderDetails = (orderId: string) => {
    navigate(`/OrderDetails/${orderId}`);
  };

  const handleTRApost = async () => {
    if (transactionId === 0) {
      toast({
        description: t("Failed to save Upload Details "),
        status: "error",
      });
      return;
    } else {
      setModalIsLoading(true);

      try {
        const headers = await getHeadersForRequest();

        const response = await axios.post(
          `${process.env.REACT_APP_TEST_API_URL}/transactions/save/traPost`,
          { transactionId: transactionId },
          { headers }
        );
        // console.log(response.data);
        if (response.data.success === 1) {
          toast({
            title: t("Successfully uploaded details to TRA"),
            status: "success",
          });
          // handleClearList();
          setModalIsLoading(false);
          getAllTransactions();
          setShowDetailsModal(false);
        } else {
          toast({
            title: response.data.message,
            status: "error",
          });
          setModalIsLoading(false);
          getAllTransactions();
          setShowDetailsModal(false);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const getAllTransactions = async () => {
    try {
      const endDate = new Date();

      const oldHeaders = await getHeadersForRequest();
      const newHeaders = {
        startDate: startDate,
        endDate: endDate.toISOString(),
        id: customerId,
      };
      const headers = { ...oldHeaders, ...newHeaders };

      const response = await axios.get<TransactionsList[]>(
        `${process.env.REACT_APP_TEST_API_URL}/transactions/customer`,
        {
          headers,
        }
      );

      const filteredOrdersDeliveryPending = await response.data.filter(
        (transaction) =>
          transaction.deliveryStatus === false && transaction.isVoid === false
      );
      const filteredOrdersPaymentPending = await response.data.filter(
        (transaction) => transaction.isVoid === false
      );

      setTransactions(
        filteredOrdersPaymentPending.sort(
          (a, b) =>
            new Date(b.invoiceDate).getTime() -
            new Date(a.invoiceDate).getTime()
        )
      );
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const groupedTransactions: {
    date: string;
    transactions: TransactionsList[];
  }[] = [];

  // Sort the groupedTransactions array in descending order of transaction date
  groupedTransactions.sort((a, b) => {
    const dateA = new Date(a.date).getTime();
    const dateB = new Date(b.date).getTime();
    return dateB - dateA;
  });

  for (let transaction of transactions) {
    const date = new Date(transaction.invoiceDate).toLocaleDateString();
    const existingGroup = groupedTransactions.find(
      (group) => group.date === date
    );
    if (existingGroup) {
      existingGroup.transactions.push(transaction);
    } else {
      groupedTransactions.push({ date, transactions: [transaction] });
    }
  }

  const handleTransactionClick = (transaction: Transaction) => {
    setShowDetailsModal(true);

    let delivered = transaction.deliveryStatus ? "delivered" : "";
    let paid = transaction.paymentStatus ? "paid" : "";
    let physical = transaction.physicalDeliveryNote ? "physical" : "";
    let tra = transaction.traReceiptNumber && transaction.traTime ? "tra" : "";
    let newGroupValues = [delivered, paid, physical, tra];

    setGroupValues(newGroupValues);
    setDeliveryNoteNumber(transaction.deliveryNoteNumber);
    setTransactionId(transaction.id);
    setTraReceiptNumber(transaction.traReceiptNumber);
    setTraReceiptTime(transaction.traTime);
  };

  const updateTransactionDetails = async () => {
    setModalIsLoading(true);
    try {
      const data = {
        paymentStatus: groupValues.includes("paid"),
        paymentDate: groupValues.includes("paid") ? new Date() : null,
        transactionId: transactionId,
        deliveryStatus: groupValues.includes("delivered"),
        deliveryDate: groupValues.includes("delivered") ? new Date() : null,
        invoiceDate: new Date(),
        physicalDeliveryNote: groupValues.includes("physical"),
        deliveryNoteNumber: groupValues.includes("physical")
          ? deliveryNoteNumber
          : "",
        traReceiptNumber: traReceiptNumber,
        traReceiptTime: traReceiptTime,
      };

      if (data.deliveryNoteNumber === "" && data.physicalDeliveryNote) {
        toast({ title: t("Invalid Delivery Note Number") });
        setModalIsLoading(false);
        return;
      }

      const headers = await getHeadersForRequest();
      await axios
        .post(
          `${process.env.REACT_APP_TEST_API_URL}/transactions/update`,
          {
            data,
          },
          { headers }
        )
        .then((response) => {
          console.log(response.data);
          toast({
            title: t("Transaction details updated successfully"),
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          setShowDetailsModal(false);
          getAllTransactions();
          setModalIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setModalIsLoading(false);
          toast({
            title: t("Error updating transaction"),
            status: "warning",
            duration: 9000,
            isClosable: true,
          });
        });
    } catch (error) {}
  };
  const exportToExcel = (data: any[], fileName: string) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };
  const voidTransaction = async () => {
    try {
      const headers = await getHeadersForRequest();
      await axios
        .post(
          `${process.env.REACT_APP_TEST_API_URL}/transactions/delete`,
          {
            transactionId: transactionId,
          },
          { headers }
        )
        .then((response) => {
          console.log(response.data);
          toast({
            title: t("Transaction VOID successful"),
            status: "warning",
            duration: 9000,
            isClosable: true,
          });
          setShowDetailsModal(false);
          getAllTransactions();
        })
        .catch((error) => {
          console.error(error);
          toast({
            title: t("Error, please try again later"),
            status: "warning",
            duration: 9000,
            isClosable: true,
          });
        });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Stack>
      <Stack position="sticky" top={0} zIndex={1} width="full">
        <Nav pageName={"Order History"}/>
      </Stack>
      <Stack flex={1}>
        <Stack pt={2} pb={10} px={3}>
          {isLoading ? (
            <Center h={600}>
              <Loading />
            </Center>
          ) : (
            <>
              <HStack justifyContent={"space-between"}>
                <Stack>
                  <Text>{customerName}</Text>
                </Stack>
                <HStack>
                  <Stack>
                    <Text>{t("Start Date")}</Text>
                  </Stack>
                  <Stack>
                    <Input
                      minW={"100px"}
                      placeholder={t("Start Date")}
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </Stack>
                  <Stack>
                    <Button onClick={() => getAllTransactions()}>
                      {t("Apply")}
                    </Button>
                  </Stack>{" "}
                  <Button
                    onClick={() => {
                      const allTransactions = groupedTransactions.flatMap(
                        (group) => group.transactions
                      );
                      exportToExcel(allTransactions, `${customerName} Orders`);
                    }}
                  >
                    Export to Excel
                  </Button>
                </HStack>
              </HStack>

              <Text>
                {t("Record count:")} {transactions.length}
              </Text>
              {groupedTransactions.map((groupedTransaction, index) => (
                <Stack key={index}>
                  <Heading size="md" my={2}></Heading>
                  {groupedTransaction.transactions.map((transaction, index) => (
                    <HStack>
                      <Stack
                        flex={1}
                        px={5}
                        key={index}
                        onContextMenu={(e) => {
                          e.preventDefault();
                          if (transaction.isVoid) {
                            toast({
                              description: t("Cannot edit a VOID receipt"),
                              status: "warning",
                              duration: 9000,
                              isClosable: true,
                            });
                          } else {
                            handleTransactionClick(transaction);
                          }
                        }}
                        onClick={() =>
                          navigateToOrderDetails(transaction.transactionNumber)
                        }
                      >
                        <TransactionCard transaction={transaction} />
                      </Stack>
                      <Stack>
                        <IconButton
                          size="lg"
                          fontSize="lg"
                          // variant="ghost"
                          // color="current"
                          marginLeft="2"
                          onClick={() => {
                            if (transaction.isVoid) {
                              toast({
                                description: t("Cannot edit a VOID receipt"),
                                status: "warning",
                                duration: 9000,
                                isClosable: true,
                              });
                            } else {
                              handleTransactionClick(transaction);
                            }
                          }}
                          icon={<FaPencilAlt />}
                          aria-label={`Edit Transaction Details`}
                        />

                        <IconButton
                          size="lg"
                          fontSize="lg"
                          // variant="ghost"
                          // color="current"
                          marginLeft="2"
                          onClick={() => {
                            navigateToOrderDetails(
                              transaction.transactionNumber
                            );
                          }}
                          icon={<FaEye />}
                          aria-label={`View Transaction Details`}
                        />
                      </Stack>
                    </HStack>
                  ))}
                </Stack>
              ))}
              {groupedTransactions.length < 1 && (
                <Stack alignItems={"center"} justifyContent={"center"} h={400}>
                  <Text fontSize={20} textAlign={"center"}>
                    {t("There are no Transactions to show")}
                  </Text>
                  <Text fontSize={20} textAlign={"center"}>
                    {t(
                      "Go to the Sales screen to record your first transaction!"
                    )}
                  </Text>
                </Stack>
              )}
            </>
          )}

          {/* TRANSACTION DETAILS MODAL */}
          <Modal
            isCentered
            closeOnOverlayClick={false}
            isOpen={showDetailsModal}
            onClose={() => setShowDetailsModal(false)}
            // _backdrop={{
            //   _dark: {
            //     bg: "coolGray.800",
            //   },
            //   bg: "warmGray.800",
            // }}
          >
            <ModalContent maxWidth="350">
              <ModalCloseButton />
              <ModalHeader>{t("Transaction Details")}</ModalHeader>
              <ModalBody>
                <HStack justifyContent={"space-between"}>
                  <Text>{t("Order details:")}</Text>

                  <IconButton
                    size="md"
                    fontSize="lg"
                    variant="ghost"
                    color="current"
                    marginLeft="2"
                    onClick={() => setShowConfirmationModal(true)}
                    icon={<FaTrash color="red" />}
                    aria-label={`Go to home`}
                  />
                </HStack>

                <Stack>
                  <CheckboxGroup
                    onChange={(values: string[]) => setGroupValues(values)}
                    value={groupValues}
                    // accessibilityLabel="Order Details"
                  >
                    <Checkbox value="paid" my={2}>
                      {t("Paid")}
                    </Checkbox>
                    <Checkbox value="delivered" my={2}>
                      {t("Delivered")}
                    </Checkbox>
                    <Checkbox value="physical" my={2}>
                      {t(" Physical Delivery Note")}
                    </Checkbox>
                    {Cookies.get("businessName") === "Keinth's Food" && (
                      <Checkbox value="tra" my={2}>
                        {t(" TRA Receipt")}
                      </Checkbox>
                    )}
                  </CheckboxGroup>
                </Stack>

                {/* {!traReceiptNumber &&
                  Cookies.get("businessName") !== "Keinth's Food" && (
                    <Center py={2}>
                      <Button
                        isLoading={modalIsLoading}
                        onClick={() => handleTRApost()}
                      >
                        {t("FISCALISE")}
                      </Button>
                    </Center>
                  )} */}

                <Stack>
                  {groupValues.includes("physical") && (
                    <Input
                      borderWidth={3}
                      // color={"white"}
                      fontSize="md"
                      pl={5}
                      mb={2}
                      // keyboardType="number-pad"
                      inputMode="numeric"
                      backgroundColor="transparent"
                      variant={"underlined"}
                      value={deliveryNoteNumber}
                      onChange={(e) => setDeliveryNoteNumber(e.target.value)}
                      placeholder={"Enter delivery note number"}
                    />
                  )}
                </Stack>

                <Stack>
                  {groupValues.includes("tra") && (
                    <>
                      <Input
                        borderWidth={3}
                        // color={"white"}
                        fontSize="md"
                        pl={5}
                        // keyboardType="number-pad"
                        inputMode="numeric"
                        value={traReceiptNumber}
                        backgroundColor="transparent"
                        variant={"underlined"}
                        onChange={(e) => setTraReceiptNumber(e.target.value)}
                        placeholder={"Enter TRA Receipt Number"}
                      />

                      <Input
                        borderWidth={3}
                        // color={"white"}
                        fontSize="md"
                        pl={5}
                        // keyboardType="number-pad"
                        value={traReceiptTime}
                        backgroundColor="transparent"
                        inputMode="numeric"
                        variant={"underlined"}
                        onChange={(e) => setTraReceiptTime(e.target.value)}
                        placeholder={"Enter Receipt Time"}
                      />
                    </>
                  )}
                </Stack>
              </ModalBody>
              <ModalFooter>
                <ButtonGroup>
                  <Button
                    variant="ghost"
                    colorScheme="blueGray"
                    onClick={() => {
                      setShowDetailsModal(false);
                    }}
                  >
                    {t("Cancel")}
                  </Button>
                  <Button
                    isLoading={modalIsLoading}
                    onClick={() => {
                      updateTransactionDetails();
                    }}
                  >
                    {t("Update")}
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </Modal>

          {/* CONFIRMATION MODAL */}
          <Modal
            isCentered
            closeOnOverlayClick={false}
            isOpen={showConfirmationModal}
            onClose={() => setShowConfirmationModal(false)}
          >
            <ModalContent maxWidth="350">
              <ModalCloseButton />
              <ModalHeader>{t("Alert")}</ModalHeader>
              <ModalBody>
                <Stack>
                  <Text textAlign={"center"}>
                    {t("Are you sure you want to VOID this receipt?")}
                  </Text>
                  <Text textAlign={"center"}>
                    {t("THIS ACTION IS IRREVERSIBLE!")}
                  </Text>
                </Stack>
              </ModalBody>
              <ModalFooter>
                <ButtonGroup>
                  <Button
                    variant="ghost"
                    colorScheme="blueGray"
                    onClick={() => {
                      setShowConfirmationModal(false);
                    }}
                  >
                    {t("No")}
                  </Button>
                  <Button
                    isLoading={modalIsLoading}
                    bg={"red"}
                    onClick={() => {
                      voidTransaction();
                    }}
                  >
                    {t("Yes")}
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CustomerOrdersScreen;
