// Import necessary libraries
import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  VStack,
  HStack,
  Text,
  Stack,
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
} from "@chakra-ui/react";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import moment from "moment";
import { DeleteIcon } from "@chakra-ui/icons";

const DailyCallReportForm: React.FC = () => {
  const today = new Date();
  today.setDate(today.getDate());

  const [date, setDate] = useState<string>(today.toISOString());
  const [userName, setUserName] = useState("");
  const [entries, setEntries] = useState([
    {
      customerName: "",
      area: "",
      contactPerson: "",
      position: "",
      timeIn: "",
      timeOut: "",
      detailsOfCall: "",
      remark: "",
    },
  ]);

  const handleEntryChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setEntries((prev) => {
      const updatedEntries = [...prev];
      updatedEntries[index] = { ...updatedEntries[index], [name]: value };
      return updatedEntries;
    });
  };

  const addEntry = () => {
    setEntries((prev) => [
      ...prev,
      {
        customerName: "",
        area: "",
        contactPerson: "",
        position: "",
        timeIn: "",
        timeOut: "",
        detailsOfCall: "",
        remark: "",
      },
    ]);
  };

  const removeEntry = (index: number) => {
    setEntries((prev) => prev.filter((_, i) => i !== index));
  };

  const generateExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("DAILY CALL REPORT");

    // Title
    worksheet.mergeCells("A1:H1");
    const titleCell = worksheet.getCell("A1");
    titleCell.value =
      "ARUSHA DAILY REPORT - CALL/SALES/COLLECTION/MARKET INFORMATION";
    titleCell.alignment = { horizontal: "center", vertical: "middle" };
    titleCell.font = { size: 16, bold: true, color: { argb: "44546a" } };
    titleCell.border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };

    // Date and User Name
    worksheet.addRow([moment(date).format("DD MMM YYYY") + " - " + userName]);

    // Headers
    const headerRow = worksheet.addRow([
      "CUSTOMER NAME",
      "AREA",
      "CONTACT PERSON",
      "POSITION",
      "TIME IN",
      "TIME OUT",
      "DETAILS OF CALL",
      "REMARK / FOLLOW UP PLAN",
    ]);
    headerRow.eachCell((cell: any) => {
      cell.font = { bold: true, color: { argb: "44546a" } };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "f2f2f2" },
      };
      cell.alignment = { horizontal: "center", vertical: "middle" };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    // Data
    entries.forEach((entry) => {
      const row = worksheet.addRow([
        entry.customerName,
        entry.area,
        entry.contactPerson,
        entry.position,
        entry.timeIn,
        entry.timeOut,
        entry.detailsOfCall,
        entry.remark,
      ]);
      row.eachCell((cell: any) => {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });
    });

    // Adjust column widths
    worksheet.columns = [
      { width: 20 }, // Customer Name
      { width: 15 }, // Area
      { width: 20 }, // Contact Person
      { width: 15 }, // Position
      { width: 10 }, // Time In
      { width: 10 }, // Time Out
      { width: 30 }, // Details of Call
      { width: 30 }, // Remark / Follow Up Plan
    ];

    // Save file
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(
      new Blob([buffer]),
      `DCR - Arusha - ${userName} - ${moment(date).format("DD-MM-YYYY")} .xlsx`
    );
  };

  return (
    <Box p={8} mx="auto" boxShadow="md" borderRadius="md">
      <VStack spacing={4}>
        <Text fontSize={"3xl"}>
          ARUSHA DAILY REPORT - CALL/SALES/COLLECTION/MARKET INFORMATION
        </Text>
        <HStack justifyContent={"flex-start"} w="100%">
          <FormControl flex={1} id="date" isRequired>
            {/* <FormLabel>Date</FormLabel> */}
            <Input
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </FormControl>
          <FormControl flex={1} id="userName" isRequired>
            {/* <FormLabel>Name of User</FormLabel> */}
            <Input
              type="text"
              value={userName}
              placeholder="Name"
              onChange={(e) => setUserName(e.target.value)}
            />
          </FormControl>
          <Stack flex={4}></Stack>
        </HStack>

        <TableContainer maxWidth={"100%"}>
          <Table>
            {/* <TableCaption>Data fetched from the server</TableCaption> */}
            <Thead>
              <Tr>
                <Th
                // fontSize={"lg"}
                // onClick={() => handleSort1("id")}
                >
                  Customer Name
                </Th>
                <Th
                // fontSize={"lg"}

                // onClick={() => handleSort1("invoiceDate")}
                >
                  Area
                </Th>
                <Th
                // fontSize={"lg"}
                // onClick={() => handleSort1("name")}
                >
                  Contact Person
                </Th>
                <Th
                // fontSize={"lg"}
                // onClick={() => handleSort1("name")}
                >
                  Position
                </Th>
                <Th
                // fontSize={"lg"}

                // onClick={() => handleSort1("paymentStatus")}
                // itemType="boolean"
                >
                  Time In
                </Th>
                <Th
                // fontSize={"lg"}

                // onClick={() => handleSort1("paymentStatus")}
                // itemType="boolean"
                >
                  Time Out
                </Th>
                <Th
                // fontSize={"lg"}

                // onClick={() => handleSort1("paymentStatus")}
                // itemType="boolean"
                >
                  Details of Call
                </Th>
                <Th
                // fontSize={"lg"}

                // onClick={() => handleSort1("paymentStatus")}
                // itemType="boolean"
                >
                  Remark/Follow-Up Plan
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {entries.map((entry, index) => (
                <Tr
                  key={index}
                  // onContextMenu={(e) => {
                  //   e.preventDefault();
                  //   setPosition({ x: e.pageX, y: e.pageY });
                  //   if (data.isVoid) {
                  //   } else {
                  //     handleTransactionClick(data);
                  //   }
                  // }}
                  // onClick={() => {
                  //   setShowPreviewModal(true);
                  //   setOrderId(data.transactionNumber.toString());
                  // }}
                >
                  <Td>
                    <FormControl id={`customerName-${index}`} isRequired>
                      {/* <FormLabel>Customer Name</FormLabel> */}
                      <Input
                        minW={100}
                        variant={"flushed"}
                        type="text"
                        name="customerName"
                        value={entry.customerName}
                        onChange={(e) => handleEntryChange(index, e)}
                      />
                    </FormControl>
                  </Td>
                  <Td>
                    <FormControl id={`area-${index}`} isRequired>
                      {/* <FormLabel>Area</FormLabel> */}
                      <Input
                        minW={100}
                        variant={"flushed"}
                        type="text"
                        name="area"
                        value={entry.area}
                        onChange={(e) => handleEntryChange(index, e)}
                      />
                    </FormControl>
                  </Td>
                  <Td>
                    <FormControl id={`contactPerson-${index}`} isRequired>
                      {/* <FormLabel>Contact Person</FormLabel> */}
                      <Input
                        minW={100}
                        variant={"flushed"}
                        type="text"
                        name="contactPerson"
                        value={entry.contactPerson}
                        onChange={(e) => handleEntryChange(index, e)}
                      />
                    </FormControl>
                  </Td>
                  <Td>
                    <FormControl id={`position-${index}`}>
                      {/* <FormLabel>Position</FormLabel> */}
                      <Input
                        minW={100}
                        variant={"flushed"}
                        type="text"
                        name="position"
                        value={entry.position}
                        onChange={(e) => handleEntryChange(index, e)}
                      />
                    </FormControl>
                  </Td>
                  <Td>
                    <FormControl id={`timeIn-${index}`}>
                      {/* <FormLabel>Time In</FormLabel> */}
                      <Input
                        minW={100}
                        variant={"flushed"}
                        type="time"
                        name="timeIn"
                        value={entry.timeIn}
                        onChange={(e) => handleEntryChange(index, e)}
                      />
                    </FormControl>
                  </Td>
                  <Td>
                    <FormControl id={`timeOut-${index}`}>
                      {/* <FormLabel>Time Out</FormLabel> */}
                      <Input
                        minW={100}
                        variant={"flushed"}
                        type="time"
                        name="timeOut"
                        value={entry.timeOut}
                        onChange={(e) => handleEntryChange(index, e)}
                      />
                    </FormControl>
                  </Td>
                  <Td>
                    <FormControl id={`detailsOfCall-${index}`}>
                      {/* <FormLabel>Details of Call</FormLabel> */}
                      <Textarea
                        resize={"vertical"}
                        name="detailsOfCall"
                        value={entry.detailsOfCall}
                        onChange={(e) => handleEntryChange(index, e)}
                      />
                    </FormControl>
                  </Td>
                  <Td>
                    <FormControl id={`remark-${index}`}>
                      {/* <FormLabel>Remark / Follow-Up Plan</FormLabel> */}
                      <Textarea
                        resize={"vertical"}
                        name="remark"
                        value={entry.remark}
                        onChange={(e) => handleEntryChange(index, e)}
                      />
                    </FormControl>
                  </Td>
                  <Td>
                    <Button
                      colorScheme="red"
                      onClick={() => removeEntry(index)}
                    >
                      <DeleteIcon />
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>

        {/* {entries.map((entry, index) => (
          <Box key={index} p={4} borderWidth="1px" borderRadius="md" w="full">
            <HStack>
              <FormControl id={`customerName-${index}`} isRequired>
                <FormLabel>Customer Name</FormLabel>
                <Input
                  type="text"
                  name="customerName"
                  value={entry.customerName}
                  onChange={(e) => handleEntryChange(index, e)}
                />
              </FormControl>
              <FormControl id={`area-${index}`} isRequired>
                <FormLabel>Area</FormLabel>
                <Input
                  type="text"
                  name="area"
                  value={entry.area}
                  onChange={(e) => handleEntryChange(index, e)}
                />
              </FormControl>
              <FormControl id={`contactPerson-${index}`} isRequired>
                <FormLabel>Contact Person</FormLabel>
                <Input
                  type="text"
                  name="contactPerson"
                  value={entry.contactPerson}
                  onChange={(e) => handleEntryChange(index, e)}
                />
              </FormControl>
              <FormControl id={`position-${index}`}>
                <FormLabel>Position</FormLabel>
                <Input
                  type="text"
                  name="position"
                  value={entry.position}
                  onChange={(e) => handleEntryChange(index, e)}
                />
              </FormControl>
              <FormControl id={`timeIn-${index}`}>
                <FormLabel>Time In</FormLabel>
                <Input
                  type="time"
                  name="timeIn"
                  value={entry.timeIn}
                  onChange={(e) => handleEntryChange(index, e)}
                />
              </FormControl>
              <FormControl id={`timeOut-${index}`}>
                <FormLabel>Time Out</FormLabel>
                <Input
                  type="time"
                  name="timeOut"
                  value={entry.timeOut}
                  onChange={(e) => handleEntryChange(index, e)}
                />
              </FormControl>
              <FormControl id={`detailsOfCall-${index}`}>
                <FormLabel>Details of Call</FormLabel>
                <Textarea
                  name="detailsOfCall"
                  value={entry.detailsOfCall}
                  onChange={(e) => handleEntryChange(index, e)}
                />
              </FormControl>
              <FormControl id={`remark-${index}`}>
                <FormLabel>Remark / Follow-Up Plan</FormLabel>
                <Textarea
                  name="remark"
                  value={entry.remark}
                  onChange={(e) => handleEntryChange(index, e)}
                />
              </FormControl>
              <HStack justifyContent="space-between" mt={4}>
                <Button colorScheme="red" onClick={() => removeEntry(index)}>
                  <DeleteIcon />
                </Button>
              </HStack>
            </HStack>
          </Box>
        ))} */}
        <Button colorScheme="blue" onClick={addEntry} w="full">
          Add Another Entry
        </Button>
        <Button colorScheme="teal" onClick={generateExcel} w="full">
          Generate Excel
        </Button>
      </VStack>
    </Box>
  );
};

export default DailyCallReportForm;
