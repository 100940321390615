import React, { useState } from "react";
import {
  Box,
  ButtonGroup,
  Button,
  Heading,
  Input,
  Select,
  Text,
  Stack,
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  HStack,
  Modal,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CheckboxGroup,
  Checkbox,
  useToast,
  ModalOverlay,
  IconButton,
  Center,
} from "@chakra-ui/react";

import { getHeadersForRequest, numberWithCommas } from "../utils/functions";
import axios from "axios";
import Cookies from "js-cookie";
import { ColorModeSwitcher } from "../components/ColorModeSwitcher";
import { CustomerList, SaleListItem, SupplierList } from "../utils/types";
import {
  FlavourValues,
  PacketSizesValues,
  PricesValues,
  ProductsValues,
} from "../utils/strings";
import { getCustomerList, getSupplierList } from "../utils/API_calls";
import { FaAngleLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Loading } from "../components/Loading";
import useCustomTranslation from "../hooks/useCustomTranslation";
// import Nav from "../components/navbar";

const Form1 = () => {
  const [listItems, setListItems] = useState<SaleListItem[]>([]);
  const [price, setPrice] = useState<string>("");
  const [size, setSize] = useState<string>(PacketSizesValues[0]);
  const [product, setProduct] = useState<string>(ProductsValues[0]);
  const [flavour, setFlavour] = useState<string>(FlavourValues[0]);
  const [pktNum, setPacketNumber] = useState<string>("");
  const [invoiceDate, setInvoiceDate] = useState<Date>(new Date());
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [customers, setCustomers] = useState<CustomerList[]>([]);
  const [modalIsLoading, setModalIsLoading] = useState<boolean>(false);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [prompt, setPrompt] = useState<boolean>(false);
  const [groupValues, setGroupValues] = useState<string[]>([]);
  const [deliveryNoteNumber, setDeliveryNoteNumber] = useState<string>("");
  const [receiptTotal, setReceiptTotal] = useState<number>(0);
  const [buyerIsSupplier, setBuyerIsSupplier] = useState<boolean>(false);
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [suppliers, setSuppliers] = useState<SupplierList[]>([]);
  const [traReceiptNumber, setTraReceiptNumber] = useState<string>("");
  const [traReceiptTime, setTraReceiptTime] = useState<string>("");
  const [manualPrice, setManualPrice] = useState<boolean>(true);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<number>(0);

  const { t, changeLanguage } = useCustomTranslation();

  const toast = useToast();

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInvoiceDate(e.target.valueAsDate || new Date());
  };

  const handleAddItem = () => {
    // console.log(price, size, product, packetNumber, flavour);
    if (
      price === "" ||
      size === "" ||
      parseInt(price) <= 0 ||
      parseInt(size) <= 0 ||
      product === "" ||
      pktNum === "" ||
      parseInt(pktNum) <= 0 ||
      (product.includes("Crisps") && flavour === "")
    ) {
      toast({
        title: t("Please fill all fields"),
        status: "warning",
        duration: 9000,
        isClosable: true,
      });
      return;
    } else {
      let newItem: SaleListItem;
      let packetNumber = parseInt(pktNum);

      if (
        product.includes("Crisps") ||
        product.includes("Cashewnuts") ||
        product.includes("Sticks")
      ) {
        newItem = {
          id: Date.now(),
          price,
          size,
          product,
          packetNumber,
          flavour,
        };
      } else {
        newItem = {
          id: Date.now(),
          price,
          size,
          product,
          packetNumber,
          flavour: null,
        };
      }

      // Check if the combination of product, packet size, and flavour has been added before
      const isDuplicate = listItems.some(
        (item) =>
          item.product === product &&
          item.size === size &&
          item.flavour === flavour
      );

      if (isDuplicate) {
        toast({
          title: t("This combination already exists"),
          status: "warning",
          duration: 9000,
          isClosable: true,
        });
        return;
      }

      setListItems([...listItems, newItem]);
      toast({
        title: t("Added to the list!"),
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      if (!manualPrice) {
        setPrice("");
      }
      setPacketNumber("");
    }
  };

  const handleClearList = () => {
    setListItems([]);
  };

  const retrieveCustomers = async () => {
    setModalIsLoading(true);
    const customers = await getCustomerList();
    if (customers.success === 1) {
      setCustomers(customers.data);
    } else {
      setCustomers([]);
    }
    setModalIsLoading(false);
  };

  const retrieveSuppliers = async () => {
    setModalIsLoading(true);
    const suppliers = await getSupplierList();
    if (suppliers.success === 1) {
      setSuppliers(suppliers.data);
    } else {
      setSuppliers([]);
    }
    setModalIsLoading(false);
  };

  const getTotal = () => {
    const receiptTotal = listItems.reduce((acc, item) => {
      const price = Number(item.price);
      const packetNumber = item.packetNumber;
      const itemTotal = price * packetNumber;
      return acc + itemTotal;
    }, 0);
    setReceiptTotal(receiptTotal);
  };

  const handleDeleteItem = (index: number) => {
    const newItems = [...listItems];
    newItems.splice(index, 1);
    setListItems(newItems);
    setShowConfirmationModal(false);
  };

  const handleSaveData = async () => {
    const email = Cookies.get("emailAddress");
    const data = {
      customerId: buyerIsSupplier
        ? null
        : selectedCustomer === ""
        ? null
        : selectedCustomer,
      supplierId: buyerIsSupplier ? selectedSupplier : null,
      paymentStatus: groupValues.includes("paid"),
      paymentDate: groupValues.includes("paid") ? new Date() : null,
      userId: email,
      deliveryStatus: groupValues.includes("delivered"),
      deliveryDate: groupValues.includes("delivered") ? new Date() : null,
      transactionDate: new Date(),
      physicalDeliveryNote: groupValues.includes("physical"),
      totalAmount: receiptTotal,
      items: listItems,
      invoiceDate: invoiceDate,
      transactionType: 1,
      deliveryNoteNumber: groupValues.includes("physical")
        ? deliveryNoteNumber
        : "",
      traReceiptNumber: traReceiptNumber,
      traReceiptTime: traReceiptTime,
    };

    if (data.supplierId === "") {
      toast({ description: t("Invalid Supplier") });
      return;
    }

    if (data.deliveryNoteNumber === "" && data.physicalDeliveryNote) {
      toast({ description: t("Invalid Delivery Note Number") });
      return;
    }

    setIsLoading(true);

    try {
      const headers = await getHeadersForRequest();

      const response = await axios.post(
        `${process.env.REACT_APP_TEST_API_URL}/transactions/save/sale`,
        data,
        { headers }
      );
      setIsLoading(false);
      setShowModal(false);
      handleClearList();
      toast({
        description: t("Transaction Details Saved!"),
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const handlePromptClose = () => {
    setPrompt(false);
    retrieveCustomers();
    retrieveSuppliers();
  };

  const handleInputChange = () => {
    setManualPrice(!manualPrice);
    setPrice("");
  };

  return (
    <>
      <Heading w="100%" textAlign={"center"} fontWeight="normal" mb="2%">
        {t("RecordOrder")}
      </Heading>
      <Stack px={5} alignItems={"center"} pb={10}>
        <HStack flexWrap={"wrap"} display={"flex"} justifyContent={"center"}>
          <Stack>
            <HStack alignItems={"center"} justifyContent={"center"}>
              <Text color="grey" fontSize="lg" fontFamily="">
                {t("Price")}
              </Text>

              <Button
                onClick={() => {
                  handleInputChange();
                }}
              >
                {manualPrice ? t("DropDown") : t("Manual")}
              </Button>
            </HStack>
            <Stack borderRadius={30} width={250}>
              {manualPrice ? (
                <Select
                  onChange={(e) => {
                    setPrice(e.target.value);
                  }}
                  borderRadius={30}
                  borderWidth={3}
                >
                  <option value={""} key={0}>
                    {"Select a price"}
                  </option>
                  {PricesValues.map((value) => (
                    <option value={value} key={value}>
                      {numberWithCommas(parseInt(value)) + "/="}
                    </option>
                  ))}
                </Select>
              ) : (
                <Input
                  borderWidth={3}
                  fontSize="md"
                  pl={5}
                  value={price}
                  backgroundColor="transparent"
                  variant={"underlined"}
                  onChange={(e) => {
                    setPrice(e.target.value);
                  }}
                  placeholder={t("EnterPrice")}
                  inputMode="numeric"
                />
              )}
            </Stack>
          </Stack>

          <Stack>
            <Text color="grey" fontSize="lg" fontFamily="">
              {t("PacketSize")}
            </Text>
            <Stack borderRadius={30} width={250}>
              {Cookies.get("businessName") === "Keinth's Food" ? (
                <Select
                  onChange={(e) => setSize(e.target.value)}
                  borderRadius={30}
                  borderWidth={3}
                >
                  {PacketSizesValues.map((value) => (
                    <option value={value} key={value}>
                      {value.toString() + " gms"}
                    </option>
                  ))}
                </Select>
              ) : (
                <Input
                  borderWidth={3}
                  fontSize="md"
                  pl={5}
                  value={size}
                  backgroundColor="transparent"
                  variant={"underlined"}
                  onChange={(e) => setSize(e.target.value)}
                  placeholder={t("Enter qty in gms")}
                  inputMode="numeric"
                />
              )}
            </Stack>
          </Stack>
        </HStack>

        <HStack flexWrap={"wrap"} display={"flex"} justifyContent={"center"}>
          <Stack>
            <Text color="grey" fontSize="lg" fontFamily="">
              {t("Product")}
            </Text>
            <Stack borderRadius={30} width={250}>
              <Select
                onChange={(e) => setProduct(e.target.value)}
                borderRadius={30}
                borderWidth={3}
              >
                {ProductsValues.map((value) => (
                  <option value={value} key={value}>
                    {value.toString()}
                  </option>
                ))}
              </Select>
            </Stack>
          </Stack>
          <Stack>
            <Text color="grey" fontSize="lg" fontFamily="">
              {t("Flavour")}
            </Text>
            <Stack borderRadius={30} width={250}>
              <Select
                onChange={(e) => setFlavour(e.target.value)}
                borderRadius={30}
                borderWidth={3}
                isDisabled={
                  product.includes("Crisps") ||
                  product.includes("Cashewnuts") ||
                  product.includes("Sticks")
                    ? false
                    : true
                }
              >
                {FlavourValues.map((value) => (
                  <option value={value} key={value}>
                    {value.toString()}
                  </option>
                ))}
              </Select>
            </Stack>
          </Stack>
        </HStack>

        <Text color="grey" fontSize="lg" fontFamily="">
          {t("NumberOfPackets")}
        </Text>
        <Stack width={250}>
          <Input
            fontSize="md"
            pl={5}
            borderRadius={30}
            defaultValue={pktNum}
            onChange={(e) => setPacketNumber(e.target.value)}
            value={pktNum}
            borderWidth={3}
            inputMode="numeric"
          />
        </Stack>
      </Stack>

      <Stack alignItems={"center"}>
        <Button
          variant={"solid"}
          onClick={handleAddItem}
          borderRadius={20}
          width={200}
          colorScheme="blue"
        >
          <Text>{t("Calculate")}</Text>
        </Button>
      </Stack>

      {listItems.length > 0 && (
        <Stack pt={10}>
          <TableContainer>
            <Table variant="simple">
              <TableCaption>
                {" "}
                {t("NumberOfItems")} {listItems.length}
              </TableCaption>
              <Thead>
                <Tr>
                  <Th>{t("QTY")}</Th>
                  <Th>{t("DESCRIPTION")}</Th>
                  <Th isNumeric>{t("AMOUNT")}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {listItems.map((items, key) => (
                  <Tr
                    key={key}
                    onClick={() => {
                      setSelectedItem(key);
                      setShowConfirmationModal(true);
                    }}
                  >
                    <Td>{items.packetNumber}</Td>
                    <Td>
                      {items.product} - {items.flavour} {items.size}gms
                    </Td>
                    <Td isNumeric>
                      {numberWithCommas(
                        parseInt(items.price) * items.packetNumber
                      )}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          <Stack
            flex={1}
            flexDir={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            px={10}
            py={5}
          >
            <Button colorScheme="red" onClick={handleClearList}>
              {t("CLEAR")}
            </Button>

            <Button
              colorScheme="green"
              onClick={() => {
                setShowModal(true);
                setPrompt(true);
                getTotal();
              }}
            >
              {t("SAVE")}
            </Button>
          </Stack>

          <Modal
            isCentered
            closeOnOverlayClick={false}
            isOpen={showConfirmationModal}
            onClose={() => setShowConfirmationModal(false)}
          >
            <ModalOverlay
              bg="blackAlpha.300"
              backdropFilter="blur(10px) hue-rotate(90deg)"
            />
            <ModalContent maxWidth="350">
              <ModalCloseButton />
              <ModalHeader>{t("DeleteItem")}</ModalHeader>
              <ModalBody>{t("ConfirmDeleteItem")}</ModalBody>
              <ModalFooter>
                <ButtonGroup>
                  <Button
                    variant="ghost"
                    colorScheme="blueGray"
                    onClick={() => {
                      setShowConfirmationModal(false);
                    }}
                  >
                    {t("Cancel")}
                  </Button>
                  <Button
                    colorScheme="red"
                    isLoading={isLoading}
                    onClick={() => {
                      handleDeleteItem(selectedItem);
                    }}
                  >
                    {t("Delete")}
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </Modal>

          <Modal
            isCentered
            closeOnOverlayClick={false}
            isOpen={showModal}
            onClose={() => setShowModal(false)}
          >
            <ModalOverlay
              bg="blackAlpha.300"
              backdropFilter="blur(10px) hue-rotate(90deg)"
            />
            <ModalContent maxWidth="350">
              <ModalCloseButton />
              <ModalHeader>{t("SaveOrder")}</ModalHeader>
              <ModalBody>
                {prompt ? (
                  <>
                    <Text>
                      {t("YourTotalIs")} {numberWithCommas(receiptTotal)}/=
                    </Text>
                    <Text>{t("ConfirmSaveOrder")}</Text>
                  </>
                ) : modalIsLoading ? (
                  <>
                    <Center>
                      <Loading />
                    </Center>
                  </>
                ) : (
                  <>
                    <Button
                      onClick={() => {
                        setBuyerIsSupplier(!buyerIsSupplier);
                      }}
                    >
                      {buyerIsSupplier
                        ? t("SellingSupplier")
                        : t("SellingCustomer")}
                    </Button>
                    
                    <br />
                    
                    {buyerIsSupplier ? (
                      <>
                        <Text>{t("PickSupplier")} </Text>
                        <Select
                          onChange={(e) => setSelectedSupplier(e.target.value)}
                        >
                          {suppliers.map((supplier) => (
                            <option value={supplier.id.toString()}>
                              {supplier.name}
                            </option>
                          ))}
                        </Select>
                      </>
                    ) : (
                      <>
                        <Text>{t("PickCustomer")}</Text>

                        <HStack justifyContent={"space-between"}>
                          <Select
                            flex={1}
                            onChange={(e) =>
                              setSelectedCustomer(e.target.value)
                            }
                          >
                            <option value={""}>{t("Cash")}</option>

                            {customers.map((customer) => (
                              <option value={customer.id.toString()}>
                                {customer.name}
                              </option>
                            ))}
                          </Select>
                          {/* <Stack pl={1}>
                            <Button
                            // bg={primary}
                            //  onClick={handleAddNewCustomer}
                            >
                              Add
                            </Button>
                          </Stack> */}
                        </HStack>
                      </>
                    )}

                    <br />

                    <Text>Invoice Date</Text>
                    <Stack>
                      <Input
                        placeholder="Invoice Date"
                        type="date"
                        value={invoiceDate.toISOString().substring(0, 10)}
                        onChange={handleDateChange}
                      />
                    </Stack>

                    <br />

                    <Text>{t("Orderdetails")}</Text>
                    <Stack>
                      <CheckboxGroup
                        onChange={(values: string[]) => setGroupValues(values)}
                        value={groupValues}
                      >
                        <Checkbox value="paid" my={2}>
                          {t("Paid")}
                        </Checkbox>
                        <Checkbox value="delivered" my={2}>
                          {t("Delivered")}
                        </Checkbox>
                        <Checkbox value="physical" isChecked my={2}>
                          {t("PhysicalDeliveryNote")}
                        </Checkbox>
                        <Checkbox value="tra" isChecked my={2}>
                          {t("TRAReceipt")}
                        </Checkbox>
                      </CheckboxGroup>
                    </Stack>

                    <Stack>
                      {groupValues.includes("physical") && (
                        <Input
                          borderWidth={3}
                          fontSize="md"
                          pl={5}
                          mb={2}
                          backgroundColor="transparent"
                          variant={"underlined"}
                          inputMode="numeric"
                          onChange={(e) =>
                            setDeliveryNoteNumber(e.target.value)
                          }
                          placeholder={t("deliverynotenumber")}
                        />
                      )}
                    </Stack>

                    <Stack>
                      {groupValues.includes("tra") && (
                        <>
                          <Input
                            borderWidth={3}
                            fontSize="md"
                            pl={5}
                            backgroundColor="transparent"
                            variant={"underlined"}
                            inputMode="numeric"
                            onChange={(e) =>
                              setTraReceiptNumber(e.target.value)
                            }
                            placeholder={t("ReceiptNumber")}
                          />

                          <Input
                            borderWidth={3}
                            fontSize="md"
                            pl={5}
                            backgroundColor="transparent"
                            variant={"underlined"}
                            inputMode="numeric"
                            onChange={(e) => setTraReceiptTime(e.target.value)}
                            placeholder={t("ReceiptTime")}
                          />
                        </>
                      )}
                    </Stack>
                  </>
                )}
              </ModalBody>
              <ModalFooter>
                <ButtonGroup>
                  <Button
                    variant="ghost"
                    colorScheme="blueGray"
                    onClick={() => {
                      setShowModal(false);
                    }}
                  >
                    {t("Cancel")}
                  </Button>
                  <Button
                    isLoading={isLoading}
                    onClick={() => {
                      prompt ? handlePromptClose() : handleSaveData();
                    }}
                  >
                    {t("SAVE")}
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Stack>
      )}
    </>
  );
};

export default function SalesOrder() {
  const navigate = useNavigate();
  return (
    <Center minH="100dvh" px={1}>
      <Box
        borderWidth="1px"
        rounded="lg"
        shadow="1px 1px 3px rgba(0,0,0,0.3)"
        p={6}
        // minW={"70%"}
        m="10px auto"
        as="form"
      >
        <HStack justifyContent={"space-between"} alignItems={"baseline"}>
          <IconButton
            size="md"
            fontSize="lg"
            variant="ghost"
            color="current"
            marginLeft="2"
            onClick={() => navigate("/dashboard")}
            icon={<FaAngleLeft />}
            aria-label={`Go to home`}
          />

          <Stack alignItems={"flex-end"}>
            <ColorModeSwitcher mb={2} />
          </Stack>
        </HStack>

        <Form1 />
      </Box>
    </Center>
  );
}
