import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import Nav from "../components/navbar";
import {
  Button,
  Divider,
  HStack,
  Text,
  Stack,
  Stat,
  StatLabel,
  StatNumber,
  Heading,
  useToast,
  Center,
} from "@chakra-ui/react";
import { getHeadersForRequest, numberWithCommas } from "../utils/functions";
import axios from "axios";
import { Loading } from "../components/Loading";
import useCustomTranslation from "../hooks/useCustomTranslation";

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const toast = useToast();
  const handleLogout = () => {
    Cookies.remove("userName");
    Cookies.remove("userToken");
    Cookies.remove("emailAddress");
    Cookies.remove("deviceId");
    toast({ title: "Session expired, please login again", status: "warning" });
    navigate("/login");
  };

  const { t, changeLanguage } = useCustomTranslation();

  const menuItems1 = [
    {
      icon: "calculator-variant",
      name: t("SalesOrder"),
      nav: "SalesCalculator",
    },
    {
      icon: "calculator-variant",
      name: t("Expenses"),
      nav: "ExpensesCalculator",
    },
    {
      icon: "calculator-variant",
      name: t("DirectExpenses"),
      nav: "OtherExpenses",
    },
    {
      icon: "calculator-variant",
      name: t("Payments"),
      nav: "Payments",
    },
    {
      icon: "calculator-variant",
      name: t("Transfers"),
      nav: "Transfers",
    },
    {
      icon: "calculator-variant",
      name: t("External Transfer"),
      nav: "TransfersExternal",
    },
  ];

  const menuItems1A = [
    {
      icon: "calculator-variant",
      name: t("SalesOrder"),
      nav: "SalesCalculatorGeneral",
    },
    {
      icon: "calculator-variant",
      name: t("PurchaseOrder"),
      nav: "ExpensesCalculator",
    },
    {
      icon: "calculator-variant",
      name: t("Payments"),
      nav: "Payments",
    },
    {
      icon: "calculator-variant",
      name: t("Transfers"),
      nav: "Transfers",
    },
  ];

  const menuItems2 = [
    { icon: "people-alt", name: t("Customers"), nav: "Customers" },
    { icon: "people-alt", name: t("Suppliers"), nav: "Suppliers" },
    { icon: "people-alt", name: t("Accounts"), nav: "Accounts" },
    { icon: "people-alt", name: t("Fryer"), nav: "Fryer" },
    { icon: "people-alt", name: t("Production"), nav: "Production" },
    { icon: "people-alt", name: t("Stock"), nav: "Stock" },
  ];

  const menuItems4 = [
    {
      icon: "calculator-variant",
      name: t("PendingOrders"),
      nav: "PendingOrders",
    },
    {
      icon: "calculator-variant",
      name: t("RecentOrders"),
      nav: "RecentOrders",
    },
    {
      icon: "calculator-variant",
      name: t("RecentPayments"),
      nav: "RecentPayments",
    },
    {
      icon: "calculator-variant",
      name: t("RecentExpenses"),
      nav: "RecentExpenses",
    },
  ];

  const menuItems2A = [
    { icon: "people-alt", name: t("Products"), nav: "Products" },
    { icon: "people-alt", name: t("Customers"), nav: "Customers" },
    { icon: "people-alt", name: t("Suppliers"), nav: "Suppliers" },
    { icon: "people-alt", name: t("Accounts"), nav: "Accounts" },
    {
      icon: "calculator-variant",
      name: t("PendingOrders"),
      nav: "PendingOrders",
    },
    {
      icon: "calculator-variant",
      name: t("RecentOrders"),
      nav: "RecentOrders",
    },
    {
      icon: "calculator-variant",
      name: t("RecentPayments"),
      nav: "RecentPayments",
    },
    {
      icon: "calculator-variant",
      name: t("RecentExpenses"),
      nav: "RecentExpenses",
    },
  ];

  const menuItems3 = [
    { icon: "calculator-variant", name: t("Reports"), nav: "Reports" },
    { icon: "calculator-variant", name: t("Analytics"), nav: "Analytics" },
    { icon: "calculator-variant", name: t("DCR"), nav: "PapaDCR" },
  ];

  useEffect(() => {
    handleGetBalances();
  }, []);

  const [totalReceivables, setTotalReceivables] = useState<number>(0);
  const [totalPayables, setTotalPayables] = useState<number>(0);

  const handleGetBalances = async () => {
    setIsLoading(true);

    const payable = await getPayablesBalance();
    const receivable = await getReceivablesBalance();

    // console.log(payable);

    if (payable && receivable) {
      setIsLoading(false);
    }
  };

  const getPayablesBalance = async () => {
    const headers = await getHeadersForRequest();
    try {
      const response = await axios.get<any>(
        `${process.env.REACT_APP_TEST_API_URL}/transactions/totalPayables`,
        { headers }
      );
      // console.log(response);
      if (response.data.message === "Invalid Token") {
        handleLogout();
        return;
      }
      setTotalPayables(response.data.total_sum);

      return { success: 1 };
    } catch (error) {
      console.error(error);
      return { success: 1 };
    }
  };

  const getReceivablesBalance = async () => {
    const headers = await getHeadersForRequest();
    try {
      const response = await axios.get<any>(
        `${process.env.REACT_APP_TEST_API_URL}/payments/customerBalances`,
        { headers }
      );

      if (response.data.message === "Invalid Token") {
        handleLogout();
        return;
      }
      setTotalReceivables(
        response.data.reduce(
          (total: any, customer: any) => total + Number(customer.balance),
          0
        )
      );
      return { success: 1 };
    } catch (error) {
      return { success: 1 };
    }
  };

  function getSalutation() {
    const currentHour = new Date().getHours();

    if (currentHour >= 0 && currentHour < 12) {
      return t("goodMorning");
    } else if (currentHour >= 12 && currentHour < 18) {
      return t("goodAfternoon");
    } else {
      return t("goodEvening");
    }
  }

  // const deviceDetails = {
  //   userAgent: navigator.userAgent,
  //   platform: navigator.platform,
  //   language: navigator.language,
  //   cookieEnabled: navigator.cookieEnabled,
  // };

  return (
    <Stack>
      <Stack position="sticky" top={0} zIndex={1} width="full">
        <Nav pageName={Cookies.get("businessName") || ""} />
      </Stack>
      {isLoading ? (
        <Center h={600}>
          <Loading />
        </Center>
      ) : (
        <>
          <Stack py={3} align={"center"}>
            <Heading>
              {getSalutation()} {Cookies.get("userName")}!
            </Heading>
          </Stack>

          {/* <Text>{deviceDetails.platform}</Text> */}
          <HStack>
            <Stat textAlign={"center"}>
              <StatLabel>{t("totalRecievables")}</StatLabel>
              <StatNumber>
                {totalReceivables !== null
                  ? numberWithCommas(totalReceivables)
                  : "0"}
              </StatNumber>
              {/* <StatHelpText>Feb 12 - Feb 28</StatHelpText> */}
            </Stat>
            <Stat textAlign={"center"}>
              <StatLabel>{t("totalPayables")}</StatLabel>
              <StatNumber>
                {totalPayables !== null ? numberWithCommas(totalPayables) : "0"}
              </StatNumber>
              {/* <StatHelpText>Feb 12 - Feb 28</StatHelpText> */}
            </Stat>
          </HStack>
          {/* <Bar /> */}
          <Stack p={5}>
            <Text fontSize={"x-large"} alignSelf={"flex-start"}>
              {t("transact")}
            </Text>

            <HStack pb={5} pt={3} flexWrap={"wrap"}>
              {menuItems1.map((items, index) => (
                <Button onClick={() => navigate(items.nav)}>
                  {items.name}
                </Button>
              ))}
            </HStack>

            <Stack px={10}>
              <Divider
                width={"100%"}
                height={1}
                // backgroundColor={useColorModeValue("gray.700", "gray.100")}
              />
            </Stack>
          </Stack>

          <Stack p={5}>
            <Text fontSize={"x-large"} alignSelf={"flex-start"}>
              {t("review")}
            </Text>
            <HStack pb={5} pt={3} flexWrap={"wrap"}>
              {menuItems4.map((items, index) => (
                <Button onClick={() => navigate(items.nav)}>
                  {items.name}
                </Button>
              ))}
            </HStack>
            <Stack px={10}>
              <Divider
                width={"100%"}
                height={1}
                // backgroundColor={useColorModeValue("gray.700", "gray.100")}
              />
            </Stack>
          </Stack>

          <Stack p={5}>
            <Text fontSize={"x-large"} alignSelf={"flex-start"}>
              {t("manage")}
            </Text>

            <HStack pb={5} pt={3} flexWrap={"wrap"}>
              {menuItems2.map((items, index) => (
                <Button onClick={() => navigate(items.nav)}>
                  {items.name}
                </Button>
              ))}
            </HStack>

            <Stack px={10}>
              <Divider
                width={"100%"}
                height={1}
                // backgroundColor={useColorModeValue("gray.700", "gray.100")}
              />
            </Stack>
          </Stack>

          <Stack p={5}>
            <Text fontSize={"x-large"} alignSelf={"flex-start"}>
              {t("reporting")}
            </Text>
            <HStack pb={5} pt={3} flexWrap={"wrap"}>
              {menuItems3.map((items, index) => (
                <Button onClick={() => navigate(items.nav)}>
                  {items.name}
                </Button>
              ))}
            </HStack>
            <Stack px={10}>
              <Divider
                width={"100%"}
                height={1}
                // backgroundColor={useColorModeValue("gray.700", "gray.100")}
              />
            </Stack>
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default Dashboard;
