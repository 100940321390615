import { useEffect, useState } from "react";
import { getHeadersForRequest, numberWithCommas } from "../utils/functions";
import axios from "axios";
import { InventoryData, ProductionList, StockData } from "../utils/types";
import {
  Box,
  Button,
  Card,
  Center,
  Collapse,
  Heading,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import Nav from "../components/navbar";
import { Loading } from "../components/Loading";
import { useNavigate } from "react-router-dom";
import useCustomTranslation from "../hooks/useCustomTranslation";
import moment from "moment";
import { AddIcon } from "@chakra-ui/icons";

const StockScreen = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [inventoryData, setInventoryData] = useState<InventoryData[]>([]);
  const [availableStock, setAvailableStock] = useState<StockData[]>([]);

  const { t, changeLanguage } = useCustomTranslation();
  const toast = useToast();

  useEffect(() => {
    setIsLoading(true);
    getInventory();
  }, []);

  const getInventory = async () => {
    try {
      const headers = await getHeadersForRequest();

      const response = await axios.get<InventoryData[]>(
        `${process.env.REACT_APP_TEST_API_URL}/analytics/products/cripsStock`,
        { headers }
      );
      // console.log(JSON.stringify(response.data));
      setInventoryData(response.data);
      await CalculatePotatoStockInHand(response.data);
      await CalculateBananaStockInHand(response.data);
      await CalculatePotatoRidgedStockInHand(response.data);
      CalculateOtherStockInHand(response.data);
      // CalculatePotatoRidgedStockInHand(response.data);
      // console.log(JSON.stringify(response.data));
      // const response2 = await axios.get(
      //   `${process.env.REACT_APP_TEST_API_URL}/analytics/products/bananaCrispsStock`,
      //   { headers }
      // );

      // setAvailableBananaStock(response2.data.qty);
      // console.log(response);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const CalculatePotatoStockInHand = async (inventoryData: InventoryData[]) => {
    const soldQty = inventoryData
      .filter(
        (row) =>
          row.product === "Potato Crisps" &&
          (row.flavour === "Plain" || row.flavour === "Chilli")
      )
      .reduce((total, row) => total + parseFloat(row.soldQty), 0);

    // console.log("Potato soldQty", soldQty);

    const producedQty = inventoryData.filter(
      (row) => row.product === "Potato Crisps"
    );

    // console.log("producedQty", producedQty[0].producedQty);

    // console.log("openingBal", producedQty[0].openingBalance);

    const availableQty = parseFloat(producedQty[0].producedQty) + parseFloat(producedQty[0].openingBalance) - soldQty;

    // console.log("availableQty", availableQty);

    const data = {
      product: "Potato Crisps",
      availableQty: availableQty.toFixed(2).toString(),
      totalProduced: producedQty[0].producedQty,
      totalSold: soldQty.toFixed(2).toString(),
    };

    setAvailableStock((prevStock) => {
      // Check if the product already exists in the stock
      const isDuplicate = prevStock.some(
        (item) => item.product === data.product
      );

      // If it's not a duplicate, add it to the state
      if (!isDuplicate) {
        return [...prevStock, data];
      }

      return prevStock;
    });
  };

  const CalculateBananaStockInHand = async (inventoryData: InventoryData[]) => {
    const soldQty = inventoryData
      .filter(
        (row) =>
          row.product === "Banana Crisps" &&
          (row.flavour === "Plain" || row.flavour === "Chilli")
      )
      .reduce((total, row) => total + parseFloat(row.soldQty), 0);

    // console.log("Bann soldQty", soldQty);

    const producedQty = inventoryData.filter(
      (row) => row.product === "Banana Crisps"
    );

    // console.log("producedQty", producedQty[0].producedQty);

    const availableQty = parseFloat(producedQty[0].producedQty) + parseFloat(producedQty[0].openingBalance) - soldQty;

    // console.log("availableQty", availableQty);

    const data = {
      product: "Banana Crisps",
      availableQty: availableQty.toFixed(2).toString(),
      totalProduced: producedQty[0].producedQty,
      totalSold: soldQty.toFixed(2).toString(),
    };

    setAvailableStock((prevStock) => {
      // Check if the product already exists in the stock
      const isDuplicate = prevStock.some(
        (item) => item.product === data.product
      );

      // If it's not a duplicate, add it to the state
      if (!isDuplicate) {
        return [...prevStock, data];
      }

      return prevStock;
    });
  };

  const CalculatePotatoRidgedStockInHand = async (
    inventoryData: InventoryData[]
  ) => {
    const soldQty = inventoryData
      .filter(
        (row) =>
          row.product === "Potato Crisps" &&
          row.flavour !== "Plain" &&
          row.flavour !== "Chilli"
      )
      .reduce((total, row) => total + parseFloat(row.soldQty), 0);

    // console.log("soldQty", soldQty);

    const producedQty = inventoryData.filter(
      (row) => row.product === "Potato Crisps (Ridged)"
    );

    // console.log("producedQty", producedQty[0].producedQty);

    const availableQty = parseFloat(producedQty[0].producedQty) + parseFloat(producedQty[0].openingBalance) - soldQty;

    // console.log("availableQty", availableQty);

    const data = {
      product: "Potato Crisps (Ridged)",
      availableQty: availableQty.toFixed(2).toString(),
      totalProduced: producedQty[0].producedQty,
      totalSold: soldQty.toFixed(2).toString(),
    };

    setAvailableStock((prevStock) => {
      // Check if the product already exists in the stock
      const isDuplicate = prevStock.some(
        (item) => item.product === data.product
      );

      // If it's not a duplicate, add it to the state
      if (!isDuplicate) {
        return [...prevStock, data];
      }

      return prevStock;
    });
  };

  const CalculateOtherStockInHand = async (inventoryData: InventoryData[]) => {
    inventoryData.forEach((item) => {
      const soldQty = inventoryData
        .filter((row) => row.product === item.product)
        .reduce((total, row) => total + parseFloat(row.soldQty), 0);

      // console.log("-----------------");
      // console.log(item.product);

      // console.log("soldQty", soldQty);

      const producedQty = inventoryData.filter(
        (row) => row.product === item.product
      );

      // console.log("producedQty", producedQty[0].producedQty);
      // console.log("openingBal", producedQty[0].openingBalance);

      const availableQty = parseFloat(producedQty[0].producedQty) + parseFloat(producedQty[0].openingBalance) - soldQty;

      // console.log("availableQty", availableQty);

      // console.log("-----------------");

      const data = {
        product: item.product,
        availableQty: availableQty.toFixed(2).toString(),
        totalProduced: producedQty[0].producedQty,
        totalSold: soldQty.toFixed(2).toString(),
      };

      setAvailableStock((prevStock) => {
        // Check if the product already exists in the stock
        const isDuplicate = prevStock.some(
          (item) => item.product === data.product
        );

        // If it's not a duplicate, add it to the state
        if (!isDuplicate) {
          return [...prevStock, data];
        }

        return prevStock;
      });
    });
  };

  return (
    <Stack px={2}>
      <Stack position="sticky" top={0} zIndex={1} width="full">
        <Nav pageName={"Goods Stock"} />
      </Stack>
      <Stack px={1} pb={10}>
        {isLoading ? (
          <Center h={600}>
            <Loading />
          </Center>
        ) : (
          <Stack>
            {/* {availableStock.map((item, key) => (
              <Heading>
                Avaliable Stock {item.product}:{" "}
                {numberWithCommas(parseFloat(item.availableQty))}
                KG - Tsh{" "}
                {numberWithCommas(
                  Math.round(parseFloat(item.availableQty) * 18000)
                )}
                /=
              </Heading>
            ))} */}

            {availableStock.length > 0 ? (
              <TableContainer>
                <Table variant="striped">
                  <TableCaption>{t("Data is from 30 Oct 2024")}</TableCaption>
                  <Thead>
                    <Tr>
                      <Th>{t("Product")}</Th>
                      <Th>{t("Available Stock")}</Th>
                      <Th>{t("Sold Qty")}</Th>
                      <Th>{t("Produced Qty")}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {availableStock.map((items, index) => (
                      <Tr>
                        <Td>{items.product} </Td>
                        <Td>{items.availableQty} KG</Td>
                        <Td>{items.totalSold} KG</Td>
                        <Td>{items.totalProduced} KG</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            ) : (
              <Stack alignItems={"center"} justifyContent={"center"} h={400}>
                <Text
                  fontSize={20}
                  textAlign={"center"}
                  // color={primary}
                >
                  {t("There are no Products found")}
                </Text>
              </Stack>
            )}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default StockScreen;
